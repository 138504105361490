import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Product } from '../model/product';
import { environment } from '../../environments/environment';
import { firstLine } from '../utils';

@Injectable({
  providedIn: 'root'
})

export class HeadService {

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private meta: Meta,
  ) { }

  createCanonicalLink(uri?: string): void {
    let element: Element;
    const canonicalURL = `${environment.root}${uri}`;
    const elements = this.dom.head.querySelectorAll('[rel="canonical"]');
    if (elements.length > 0) {
      element = elements[0];
      element.setAttribute('href', canonicalURL);
    } else {
      element = this.dom.createElement('link');
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', canonicalURL);
      this.dom.head.appendChild(element);
    }
  }

  setMetaTag(name: string, content: string) {
    this.meta.updateTag( { name, content }, `name="${name}"` );
  }

  setMetaDescription(description: string) {
    this.setMetaTag('description', firstLine(description));
  }

  _productStructuredData(product: Product) {
    return {
      '@context' : 'http://schema.org',
      '@type' : 'Product',
      name : firstLine(product.title),
      image : product.safeImageUrl,
      description : firstLine(product.description),
      offers : {
        '@type' : 'Offer',
        price : product.formattedPrice
      }
    };
  }

  _orgStructuredData() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.irishcelticjewellery.com',
      name: 'Irish Celtic Jewellery',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+353858666215',
        contactType: 'Customer Service'
      }
    };
  }

  removeStructuredData(): void {
    const els = [];
    els.push(...Array.from(this.dom.head.getElementsByClassName('structured-data')));
    els.forEach(el => this.dom.head.removeChild(el));
  }

  _insertStructuredData(schema: Record<string, any>): void {
    let script;
    let shouldAppend = false;
    const elements = this.dom.head.getElementsByClassName('structured-data');
    if (elements.length > 0) {
      script = elements[0];
    } else {
      script = this.dom.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', 'structured-data');
    script.type = 'application/json+ld';
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.dom.head.appendChild(script);
    }
  }

  setOrgStructuredData(): void {
    this.removeStructuredData();
    this._insertStructuredData(this._orgStructuredData());
  }

  setProductStructuredData(product: Product): void {
    this.removeStructuredData();
    this._insertStructuredData(this._productStructuredData(product));
  }

  setTwitterMetaTags(product: Product): void {
    // Twitter uses the og meta tags too
    // this.setMetaTag('twitter:title', product.title);
    // this.setMetaTag('twitter:description', product.description);
    // this.setMetaTag('twitter:image', product.safeImageUrl);
    this.setMetaTag('twitter:card', 'summary_large_image');
    this.setMetaTag('twitter:image:alt', firstLine(product.description));
  }

  setFacebookMetaTags(product: Product): void {
    this.setMetaTag('og:type', 'website');
    this.setMetaTag('og:title', firstLine(product.title));
    this.setMetaTag('og:description', firstLine(product.description));
    this.setMetaTag('og:image', product.safeImageUrl);
    this.setMetaTag('og:url', product.safeProductFullUrl);
    this.setMetaTag('og:site_name', 'Irish Celtic Jewellery');
  }
}
