import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private titleService: TitleService,
  ) { }

  ngOnInit(): void {
    const title = 'Terms';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
  }
}
