<section class="lightSection pageHeader">
  <div class="container">
    <div class="row">
      <div class="page-title col-md-5">
        <h1 *ngIf="showTitle">{{titleService.title}}</h1>
      </div>
      <div class="breadcrumb col col-md-7">
        <ol>
          <li>
            <a routerLink="/" routerLinkActive="active">Home</a>
          </li>
          <li class="active" *ngIf="breadcrumbService.safeUri !== null">
            <a [routerLink]="breadcrumbService.safeUri" routerLinkActive="active">{{breadcrumbService.breadcrumb}}</a>
          </li>
          <li class="active" *ngIf="breadcrumbService.safeUri === null">{{breadcrumbService.breadcrumb}}</li>
        </ol>
      </div>
    </div>
  </div>
</section>