<app-breadcrumb></app-breadcrumb>

<div class="container page">

  <div class="checkout-progress-indicator">
    <div class="step" [ngClass]="{'active': step.shippingDetails}">
      <div class="step-title clickable" (click)="goToStep('shipping-details')">1. Shipping</div>
    </div>
    <div class="step" [ngClass]="{'active': step.billingDetails}">
      <div class="step-title clickable" (click)="goToStep('billing-details')">2. Billing</div>
    </div>
    <div class="step" [ngClass]="{'active': step.orderSummary}">
      <div class="step-title">3. Order Summary</div>
    </div>
  </div>

  <div class="stripe-wrapper">
    <div class="cell checkout"
      [ngClass]="{'submitting': paymentState.submitting, 'submitted': paymentState.successful}">

      <form id="shipping-form" (ngSubmit)="validateShipping()" #shippingForm="ngForm" [hidden]="!step.shippingDetails">
        <fieldset>
          <legend class="card-only">Shipping Details</legend>
          <div class="row">
            <div class="field">
              <label for="shipping-name">Name</label>
              <input id="shipping-name" name="shipping-name" [(ngModel)]="data.shipping.name" class="input" type="text"
                placeholder="" required="true" autocomplete="name">
            </div>
          </div>
          <div class="row">
            <div class="field email">
              <label for="shipping-email">Email</label>
              <input id="shipping-email" name="shipping-email" [(ngModel)]="data.shipping.email" class="input"
                type="email" placeholder="" required="" autocomplete="email">
            </div>
            <div class="field phone">
              <label for="shipping-phone">Phone</label>
              <input id="shipping-phone" name="shipping-phone" [(ngModel)]="data.shipping.phone" class="input"
                type="text" placeholder="" required="" autocomplete="tel">
            </div>
          </div>
          <div class="row">
            <div class="field address">
              <label for="shipping-address">Address</label>
              <input id="shipping-address" name="shipping-address" [(ngModel)]="data.shipping.address.line1"
                class="input" type="text" placeholder="" required="" autocomplete="address-line1">
            </div>
            <div class="field city">
              <label for="shipping-city">City</label>
              <input id="shipping-city" name="shipping-city" [(ngModel)]="data.shipping.address.city" class="input"
                type="text" placeholder="" required="" autocomplete="address-level2">
            </div>
          </div>
          <div class="row" data-locale-reversible="">
            <div class="field country">
              <label for="shipping-country">Country</label>
              <select id="shipping-country" name="shipping-country" [(ngModel)]="data.shipping.address.country"
                class="input">
                <option *ngFor="let c of countries; let i = index" value="{{c.code}}">{{c.name}}</option>
              </select>

            </div>
            <div class="field state">
              <label for="shipping-state">State</label>
              <input id="shipping-state" name="shipping-state" [(ngModel)]="data.shipping.address.state"
                class="input empty" type="text" placeholder="" required="" autocomplete="address-level1">
            </div>
            <div class="field zip">
              <label for="shipping-zip">ZIP</label>
              <input id="shipping-zip" name="shipping-zip" [(ngModel)]="data.shipping.address.postal_code"
                class="input empty" type="text" placeholder="" required="" autocomplete="postal-code">
            </div>
          </div>
          <div class="row">
            <div class="field">
              <input id="shipping-address-use-as-billing" name="shipping-address-use-as-billing"
                [(ngModel)]="data.useShippingAsBilling" type="checkbox" class="checkbox-custom form-check-input">
              <label class="checkbox-custom-label form-check-label" for="shipping-address-use-as-billing">Use same
                address as billing address</label>
            </div>
          </div>
          <button type="submit">Next</button>
        </fieldset>
        <div class="error" [hidden]="errorMessage.length === 0" role="alert">
          <i class="fas fa-times"></i>
          <span class="message">{{errorMessage}}</span>
        </div>
      </form>

      <form id="checkout-form" (ngSubmit)="submitPayment()" #checkoutForm="ngForm" [hidden]="!step.billingDetails">
        <div id="checkout-payment-request">
          <!--Stripe paymentRequestButton Element inserted here-->
        </div>
        <fieldset>
          <legend class="card-only" [hidden]="canMakePaymentRequest">Pay with card</legend>
          <legend class="payment-request-available" [hidden]="canMakePaymentRequest">Or enter card details</legend>
          <div class="row">
            <div class="field">
              <label for="checkout-name">Name</label>
              <input id="checkout-name" name="billing-name" [(ngModel)]="data.billing.name" class="input" type="text"
                placeholder="" required="" autocomplete="name">
            </div>
          </div>
          <div class="row">
            <div class="field email">
              <label for="checkout-email">Email</label>
              <input id="checkout-email" name="billing-email" [(ngModel)]="data.billing.email" class="input"
                type="email" placeholder="" required="" autocomplete="email">
            </div>
            <div class="field phone">
              <label for="checkout-phone">Phone</label>
              <input id="checkout-phone" name="billing-phone" [(ngModel)]="data.billing.phone" class="input" type="text"
                placeholder="" required="" autocomplete="tel">
            </div>
          </div>
          <div class="row">
            <div class="field address">
              <label for="checkout-address">Address</label>
              <input id="checkout-address" name="billing-address" [(ngModel)]="data.billing.address.line1" class="input"
                type="text" placeholder="" required="" autocomplete="address-line1">
            </div>
            <div class="field city">
              <label for="checkout-city">City</label>
              <input id="checkout-city" name="billing-city" [(ngModel)]="data.billing.address.city" class="input"
                type="text" placeholder="" required="" autocomplete="address-level2">
            </div>
          </div>
          <div class="row" data-locale-reversible="">
            <div class="field country">
              <label for="checkout-country">Country</label>
              <select id="checkout-country" name="billing-country" [(ngModel)]="data.billing.address.country"
                class="input">
                <option *ngFor="let c of countries; let i = index" value="{{c.code}}"
                  ng-selected="c === data.billing.address.country">{{c.name}}</option>
              </select>

            </div>
            <div class="field state">
              <label for="checkout-state">State</label>
              <input id="checkout-state" name="billing-state" [(ngModel)]="data.billing.address.state"
                class="input empty" type="text" placeholder="" required="" autocomplete="address-level1">
            </div>
            <div class="field zip">
              <label for="checkout-zip">ZIP</label>
              <input id="checkout-zip" name="billing-zip" [(ngModel)]="data.billing.address.postal_code"
                class="input empty" type="text" placeholder="" required="" autocomplete="postal-code">
            </div>
          </div>
          <div class="row">
            <div class="field">
              <label for="checkout-card-element">Card</label>
              <div id="checkout-card-element"></div>
            </div>
          </div>
          <button type="submit" *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart">Pay
            {{shoppingCart.formattedTotalAmount}}</button>
        </fieldset>
        <div class="error" [hidden]="errorMessage.length === 0" role="alert">
          <i class="fas fa-times"></i>
          <span class="message">{{errorMessage}}</span>
        </div>
      </form>

      <div class="success">
        <div class="icon">
          <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <circle class="border" cx="42" cy="42" r="40"></circle>
            <path class="checkmark" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"></path>
          </svg>
        </div>
        <h1 style="width: 100%">Payment received!</h1>
        <h3 class="title">Payment successful</h3>
        <p class="message">Thank you for shopping with Irish Celtic Jewellery. An email has been sent to you, detailing
          your order, for your records.</p>
      </div>

    </div>

  </div>
</div>