import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { FavouritesService } from '../../services/favourites.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    public favouritesService: FavouritesService,
    private headService: HeadService,
    private titleService: TitleService,
    ) { }

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    const title = 'My Favourites';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
    this.favouritesService.fetch();
  }
}
