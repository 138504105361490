import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { ShoppingCartService } from './shopping-cart.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({ providedIn: 'root' })
export class PaymentIntentService {

  _paymentIntentClientSecret: string;
  _paymentMetadata: Record<string, unknown>;

  constructor(
    private http: HttpClient,
    public shoppingCartService: ShoppingCartService,
    private toasterService: ToastrService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  paymentIntentUrl = (): string => `${environment.api}/v1/payment/intention`;
  paymentBuyUrl = (): string => `${environment.api}/v1/payment/buy`;
  paymentCompletedUrl = (): string => `${environment.api}/v1/payment/completed`;

  public get paymentIntentClientSecret(): string {
    return this._paymentIntentClientSecret;
  }

  create(): void {
    this.http.post<any>(this.paymentIntentUrl(), {}).subscribe(result => {
      this._paymentIntentClientSecret = result ? result.client_secret : undefined;
      console.log(`payment-intent create: `, this._paymentIntentClientSecret);
    });
    this.googleAnalyticsService.emitEvent('begin_checkout', 'ecommerce');
  }

  buy(saleData: Record<string, unknown>): void {
    this.http.post<any>(this.paymentBuyUrl(), saleData).subscribe(result => {
      this._paymentMetadata = result;
      console.log(`payment-intent buy: `, this._paymentMetadata);
    });
    this.googleAnalyticsService.emitEvent('purchase', 'ecommerce');
  }

  completed(): void {
    this.http.post<any>(this.paymentCompletedUrl(), {}).subscribe(result => {
      this._paymentIntentClientSecret = undefined;
      this.shoppingCartService.get();
      console.log(`payment-intent completed: `, result);
    });
    //this.googleAnalyticsService.emitEvent('purchase', 'ecommerce');
  }
}
