import { PLATFORM_ID, Injectable, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import * as auth0 from 'auth0-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class AuthService {

  auth0 = new auth0.WebAuth(environment.auth0);
  private _idToken: string;
  private _accessToken: string;
  private _expiresAt: number;
  private _isLoggedIn: boolean;
  private _userInfo: any;
  private _tokenRenewalTimeout: any;

  // private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  // private isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor(
    private zone: NgZone,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private googleAnalyticsService: GoogleAnalyticsService
    ) {
    const authInfo = isPlatformBrowser(this.platformId) ? JSON.parse(localStorage.getItem('authInfo')) : undefined;
    if (authInfo) {
      this._isLoggedIn = authInfo._isLoggedIn;
      this._idToken = authInfo._idToken;
      this._accessToken = authInfo._accessToken;
      this._expiresAt = authInfo._expiresAt;
      this._userInfo = authInfo._userInfo;
      this.renewTokens();
    } else {
      this._isLoggedIn = false;
      this._idToken = '';
      this._accessToken = '';
      this._expiresAt = 0;
      this._userInfo = undefined;
    }
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get idToken(): string {
    return this._idToken;
  }

  public login(): void {
    localStorage.setItem('returnTo', this.router.url);
    this.googleAnalyticsService.emitEvent('view_item', 'engagement');
    this.auth0.authorize();
  }

  public handleLoginCallback(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        if (isPlatformBrowser(this.platformId)) {
          window.location.hash = '';
        }
        this._setSession(authResult);
      } else if (err) {
        console.log(`Error: ${err.error}. Check the console for further details. ${err.message}`);
      }
      let returnTo: string;
      if (isPlatformBrowser(this.platformId)) {
        returnTo = localStorage.getItem('returnTo') || '/home';
      } else {
        returnTo = '/home';
      }
      this.zone.run(() => {
        this.router.navigate([returnTo]);
      });
    });
  }

  public renewTokens(): void {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this._setSession(authResult);
        // this.isAuthenticatedSubject$.next(true);
      } else if (err) {
        console.log(`Could not get a new token (${err.error}: ${err.error_description}).`);
        this.logout();
      }
    });
  }

  public logout(): void {
    // Remove tokens and expiry time
    this._isLoggedIn = false;
    this._idToken = '';
    this._accessToken = '';
    this._expiresAt = 0;
    clearTimeout(this._tokenRenewalTimeout);
    localStorage.removeItem('authInfo');
    // Navigate to the Auth0 logout URL, which will log out SSO sessions and redirect to the home page
    window.location.href = environment.auth0.logoutUrl;
  }

  private getUserInfo(authResult): void {
    this.auth0.client.userInfo(authResult.accessToken, (err, userInfo) => {
      if (userInfo) {
        this._setSession(authResult, userInfo);
      } else {
        console.log(`getUserInfo error: `, err);
      }
    });
  }

  private getRole(): string {
    if (this._userInfo && this._userInfo['https://www.irishcelticjewellery.com/roles']) {
      return this._userInfo['https://www.irishcelticjewellery.com/roles'][0];
    }
  }

  private scheduleRenewal(): void {
    if(isPlatformBrowser(this.platformId)) {
      const delay = this._expiresAt - Date.now();
      if (delay > 0) {
        console.log(`[auth.service] scheduling token renewal in ${delay / 1000} secs`);
        this._tokenRenewalTimeout = setTimeout(() => {
          this.renewTokens();
        }, delay);
      }
    }
    }

  private _setSession(authResult, userInfo = undefined): void {
    // Set the time that the access token will expire at
    this._isLoggedIn = true;
    this._userInfo = userInfo || authResult.idTokenPayload;
    this._accessToken = authResult.accessToken;
    this._idToken = authResult.idToken;
    this._expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    const { _isLoggedIn, _userInfo, _accessToken, _idToken, _expiresAt } = this;
    const authInfo = { _isLoggedIn, _userInfo, _accessToken, _idToken, _expiresAt };
    console.log(`AuthInfo:`, authInfo);
    localStorage.setItem('authInfo', JSON.stringify(authInfo));
    this.scheduleRenewal();
  }

  public get isAuthenticated(): boolean {
    // Check whether the current time is past the access token's expiry time
    return this._isLoggedIn && new Date().getTime() < this._expiresAt;
  }

  public get isCustomer(): boolean {
    return this._isLoggedIn && this.getRole() === 'customer';
  }

  public get isAdmin(): boolean {
    return this._isLoggedIn && this.getRole() === 'admin';
  }

  public get name(): boolean {
    // Check whether the current time is past the access token's expiry time
    return this._userInfo.name;
  }
}
