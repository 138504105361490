import { get as _get, each as _each, split as _split } from 'lodash-es';
import { applyPriceFormatting, applyImageFormatting, countryMap, formatPrice } from '../utils';
import { Product } from './product';
import { environment } from '../../environments/environment';

export class Order {
  orderNumber: string;
  numberOfItems: number;
  shippingAmount: number;
  customsDutyAmount: number;
  totalAmount: number;
  vatAmount: number;
  currency: string;
  exchangeRate: string;
  status: string;
  notes: string;
  timeOfPurchase: Date;
  shippedDate: string;
  shippingName: string;
  shippingCompany: string;
  shippingAddress_1: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string;
  shippingCountry: string;
  shippingPhone: string;
  billingName: string;
  billingCompany: string;
  billingAddress_1: string;
  billingCity: string;
  billingZip: string;
  billingState: string;
  billingCountry: string;
  billingPhone: string;
  purchasedByUser: string;
  paymentIntentId: string;
  saleItems: Array<Product>;
  safeOrderUrl: string;

  charge?: any;
  formattedBillingCountry?: string;
  formattedCustomsDutyAmount?: string;
  formattedTimeOfPurchase?: string;
  formattedTotalAmount?: string;
  formattedVatAmount?: string;
  hasCharge?: boolean;
  paymentIntent?: any;
  paidWithCard?: boolean;


  constructor() { }

  static _applyPriceFormatting(order: Order) {
    order.formattedTotalAmount = formatPrice(order.totalAmount);
    order.formattedVatAmount = formatPrice(order.vatAmount);
    order.formattedCustomsDutyAmount = formatPrice(order.customsDutyAmount);
  }

  static enrich(order: Order): void {
    this._applyPriceFormatting(order);

    order.formattedTimeOfPurchase = new Date(order.timeOfPurchase).toLocaleString();
    order.safeOrderUrl = `/v1/admin/orders/${order.orderNumber}`;
    order.formattedBillingCountry = countryMap[order.billingCountry] ? countryMap[order.billingCountry].name : order.billingCountry;

    order.paymentIntent.formattedAmount = formatPrice(order.paymentIntent.stripe.amount);
    order.paymentIntent.formattedAmountReceived = formatPrice(order.paymentIntent.stripe.amount_received);
    order.hasCharge = _get(order, 'paymentIntent.stripe.charges.total_count') > 0;
    order.charge = _get(order, 'paymentIntent.stripe.charges.data[0]');
    order.paidWithCard = _get(order, 'charge.payment_method_details.type') === 'card';

    _each(order.saleItems, (product) => {
      applyPriceFormatting(product);
      applyImageFormatting(product);
      product.safeProductUrl = `/product/${product.slug}`;
    });
  }
}
