import { PLATFORM_ID, Inject, Component, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-trust-pilot',
  templateUrl: './trust-pilot.component.html',
  styleUrls: ['./trust-pilot.component.scss']
})
export class TrustPilotComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.Trustpilot) {
        const trustboxRef = document.getElementById('trustpilot-box');
        window.Trustpilot.loadFromElement(trustboxRef);
      }
    }
  }

}
