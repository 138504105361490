<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">
    <div class="row">
      <div class="col-6 offset-sm-3">
        <p><b>Is A Sale On?</b></p>
        <div class="row">
          <div class="col-sm-2 offset-sm-2">
            <input id="settings-sale-on-yes" class="radio-custom form-check-input" [(ngModel)]="settings.saleOn"
              name="sale-on" type="radio" [value]="true" (change)="saleOnHandler($event)">
            <label for="settings-sale-on-yes" class="radio-custom-label form-check-label">Yes</label>
          </div>
          <div class="col-sm-2">
            <input id="settings-sale-on-no" class="radio-custom form-check-input" [(ngModel)]="settings.saleOn"
              name="sale-on" type="radio" [value]="false" (change)="saleOnHandler($event)">
            <label for="settings-sale-on-no" class="radio-custom-label form-check-label">No</label>
          </div>
        </div>
        <p><b>Sale percentage?</b></p>
        <div class="row">
          <div class="col-sm-3 offset-sm-2">
            <select class="form-control" id="settings-sale-percent" [(ngModel)]="settings.salePercent"
              name="sale-percent" (change)="salePercentHandler($event)">
              <option value="0.1">10%</option>
              <option value="0.2">20%</option>
              <option value="0.25">25%</option>
              <option value="0.3">30%</option>
              <option value="0.4">40%</option>
              <option value="0.5">50%</option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>