<aside id="mobile-menu" [ngClass]="{'show-menu': mobileMenuService.isOpen}">
  <div>
    <ul>
      <li *ngIf="!auth.isAuthenticated">
        <a [routerLink]="" (click)="auth.login()"><i class="fas fa-user"></i> Login / Sign Up</a>
      </li>
      <li *ngIf="auth.isAuthenticated" class="secondary">
        <a [routerLink]=""><i class="fas fa-user"></i> {{auth.name}}</a>
      </li>
      <li><a (click)="mobileMenuService.state.shop.isOpen = true">Shop <i class="fas fa-chevron-right"></i></a>
        <div class="sub-menu level1" [ngClass]="{'show-menu': mobileMenuService.state.shop.isOpen}">
          <ul>
            <li class="header"><a (click)="mobileMenuService.state.shop.isOpen = false"><i
                  class="fas fa-chevron-left"></i>Shop</a></li>
            <li><a (click)="mobileMenuService.state.shop.engagementRings.isOpen = true">Engagement Rings <i
                  class="fas fa-chevron-right"></i></a>
              <div class="sub-menu level2"
                [ngClass]="{'show-menu': mobileMenuService.state.shop.engagementRings.isOpen}">
                <ul>
                  <li class="header"><a (click)="mobileMenuService.state.shop.engagementRings.isOpen = false"><i
                        class="fas fa-chevron-left"></i>Engagement Rings</a></li>
                  <li><a routerLink="/collection/engagement-rings/classic-solitaire-rings">Solitaire Engagement
                      Rings</a></li>
                  <li><a routerLink="/collection/engagement-rings/celtic-engagement-rings">Celtic Engagement Rings</a>
                  </li>
                </ul>
              </div>
            </li>
            <li><a (click)="mobileMenuService.state.shop.weddingRings.isOpen = true">Wedding Rings <i
                  class="fas fa-chevron-right"></i></a>
              <div class="sub-menu level2" [ngClass]="{'show-menu': mobileMenuService.state.shop.weddingRings.isOpen}">
                <ul>
                  <li class="header"><a (click)="mobileMenuService.state.shop.weddingRings.isOpen = false"><i
                        class="fas fa-chevron-left"></i>Wedding Rings</a></li>
                  <li><a routerLink="/collection/wedding-bands/celtic-wedding-bands">Celtic Wedding Bands</a></li>
                  <li><a routerLink="/collection/wedding-bands/diamond-wedding-bands">Diamond Wedding Rings</a></li>
                </ul>
              </div>
            </li>
            <li><a (click)="mobileMenuService.state.shop.modernIrish.isOpen = true">Modern Irish <i
                  class="fas fa-chevron-right"></i></a>
              <div class="sub-menu level2" [ngClass]="{'show-menu': mobileMenuService.state.shop.modernIrish.isOpen}">
                <ul>
                  <li class="header"><a (click)="mobileMenuService.state.shop.modernIrish.isOpen = false"><i
                        class="fas fa-chevron-left"></i>Modern Irish</a></li>
                  <li><a routerLink="/collection/modern-irish/bangles">Bangles</a></li>
                  <li><a routerLink="/collection/modern-irish/bracelets">Bracelets</a></li>
                  <li><a routerLink="/collection/modern-irish/chains">Chains</a></li>
                  <li><a routerLink="/collection/modern-irish/earrings">Earrings</a></li>
                  <li><a routerLink="/collection/modern-irish/pendants">Pendants</a></li>
                  <li><a routerLink="/collection/modern-irish/rings">Rings</a></li>
                  <li><a routerLink="/collection/modern-irish/watches">Watches</a></li>
                </ul>
              </div>
            </li>
            <li><a (click)="mobileMenuService.state.shop.celtic.isOpen = true">Celtic &amp; Claddagh <i
                  class="fas fa-chevron-right"></i></a>
              <div class="sub-menu level2" [ngClass]="{'show-menu': mobileMenuService.state.shop.celtic.isOpen}">
                <ul>
                  <li class="header"><a (click)="mobileMenuService.state.shop.celtic.isOpen = false"><i
                        class="fas fa-chevron-left"></i>Celtic &amp; Claddagh</a></li>
                  <li><a routerLink="/collection/celtic/bangles">Bangles</a></li>
                  <li><a routerLink="/collection/celtic/bracelets">Bracelets</a></li>
                  <li><a routerLink="/collection/celtic/brooches">Brooches</a></li>
                  <li><a routerLink="/collection/celtic/cufflinks">Cufflinks</a></li>
                  <li><a routerLink="/collection/celtic/earrings">Earrings</a></li>
                  <li><a routerLink="/collection/celtic/pendants">Pendants</a></li>
                  <li><a routerLink="/collection/celtic/rings">Rings</a></li>
                </ul>
              </div>
            </li>
            <li><a (click)="mobileMenuService.state.shop.houseOfLor.isOpen = true">House Of Lor <i
                  class="fas fa-chevron-right"></i></a>
              <div class="sub-menu level2" [ngClass]="{'show-menu': mobileMenuService.state.shop.houseOfLor.isOpen}">
                <ul>
                  <li class="header"><a (click)="mobileMenuService.state.shop.houseOfLor.isOpen = false"><i
                        class="fas fa-chevron-left"></i>House Of Lor</a></li>
                  <li><a routerLink="/collection/house-of-lor/bangles">Bangles</a></li>
                  <li><a routerLink="/collection/house-of-lor/bracelets">Bracelets</a></li>
                  <li><a routerLink="/collection/house-of-lor/chains">Chains</a></li>
                  <li><a routerLink="/collection/house-of-lor/earrings">Earrings</a></li>
                  <li><a routerLink="/collection/house-of-lor/pendants">Pendants</a></li>
                  <li><a routerLink="/collection/house-of-lor/rings">Rings</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li><a (click)="mobileMenuService.state.aboutUs.isOpen = true">About Us <i class="fas fa-chevron-right"></i></a>
        <div class="sub-menu level1" [ngClass]="{'show-menu': mobileMenuService.state.aboutUs.isOpen}">
          <ul>
            <li class="header"><a (click)="mobileMenuService.state.aboutUs.isOpen = false"><i
                  class="fas fa-chevron-left"></i>About Us</a></li>
            <li><a routerLink="/about-us">About Us</a></li>
            <li><a routerLink="/contact-us">Contact Us</a></li>
            <!-- <li><a routerLink="/delivery-information">Delivery Information</a></li> -->
            <li><a routerLink="/returns">Returns Policy</a></li>
            <li><a routerLink="/privacy">Privacy</a></li>
            <li><a routerLink="/disclaimer">Disclaimer</a></li>
            <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
            <li><a routerLink="/terms">Terms &amp; Conditions</a></li>
          </ul>
        </div>
      </li>
      <li><a routerLink="/favourites"><i class="fas fa-heart"></i> Favourites</a></li>
      <li><a routerLink="/shopping-cart"><i class="fas fa-shopping-cart"></i> Shopping Cart</a></li>
      <li *ngIf="auth.isAuthenticated">
        <a [routerLink]="" (click)="auth.logout()"><i class="fas fa-sign-out-alt"></i> Log Out</a>
      </li>
      <!-- <li><a routerLink="/choosing-a-ring">Choosing A Ring</a></li> -->
    </ul>
  </div>
</aside>