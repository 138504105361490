<app-breadcrumb></app-breadcrumb>

<div class="container page">
  <div class="row">
    <div class="col-md-12">

      <div class="body">
        <div>
          <div align="center" class="title" style="text-align: left; line-height: 1.5;">
            <a id="_4r5vko5di6yg" name="_4r5vko5di6yg"></a><strong><span
                style="line-height: 150%; font-size: 26px;">DISCLAIMER</span></strong>
          </div>
          <div align="center" style="text-align:center;line-height:150%;">
            <a id="_l2jmcqu2bv4x" name="_l2jmcqu2bv4x"></a>
          </div>
          <div align="center" class="subtitle" style="text-align: left; line-height: 150%;">
            <br>
          </div>
          <div align="center" class="subtitle" style="text-align: left; line-height: 150%;">
            <span style="color: rgb(127,127,127); font-size: 15px; text-align: justify;"><strong>Last
                updated&nbsp;</strong><strong>June 01, 2020</strong></span>
          </div>
          <div style="text-align: justify; line-height: 1.5;">
            <br>
          </div>
          <div style="text-align: justify; line-height: 1.5;">
            <br>
          </div>
          <div class="heading_1">
            <a id="_xs0r05tcjblb" name="_xs0r05tcjblb"></a><strong><span
                style="line-height: 115%; font-size: 19px;">WEBSITE DISCLAIMER</span></strong>
          </div>
        </div>
        <div style="line-height: 1.2;">
          <br>
        </div>
        <div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">The information provided by Jewellery Solutions
              (“we,” “us” or “our”) on <a class="link" href="https://www.irishcelticjewellery.com"
                target="_blank">https://www.irishcelticjewellery.com</a> (the “Site”) is for general informational
              purposes only. All information on the Site is provided in good faith, however we make no representation or
              warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability,
              availability or completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
              LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
              RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY
              INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</span>
          </div>
        </div>
        <div style="line-height: 1.2;">
          <br>
        </div>
        <div>
          <div class="MsoNormal">
            <a id="_x1u8x12nt00e" name="_x1u8x12nt00e"></a>
          </div>
          <div class="MsoNormal">
            <a id="_wfmrqujylbbj" name="_wfmrqujylbbj"></a>
          </div>
        </div>
        <div></div>
        <div>
          <div style="text-align: justify;"></div>
        </div>
        <div>
          <div class="heading_1" style="text-align: justify;">
            <strong><span style="line-height: 115%; font-size: 19px;">TESTIMONIALS DISCLAIMER</span></strong>
          </div>
          <div style="text-align: justify; line-height: 1.2;">
            <br>
          </div>
        </div>
        <div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">The Site may contain testimonials by users of our
              products and/or services. These testimonials reflect the real-life experiences and opinions of such users.
              However, the experiences are personal to those particular users, and may not necessarily be representative
              of all users of our products and/or services. We do not claim, and you should not assume, that all users
              will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.&nbsp;</span>
          </div>
          <div class="MsoNormal">
            <br>
          </div><span style="font-size: 15px;"></span>
          <div class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">The testimonials on the Site are
                submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted.
                They appear on the Site verbatim as given by the users, except for the correction of grammar or typing
                errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial
                contained extraneous information not relevant to the general public.<span
                  style="font-size: 15px;"></span></span></span>
          </div>
          <div class="body_text" style="line-height: 1.2;">
            <br>
          </div>
          <div class="body_text" style="line-height: 1.5;">
            <span style="mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;">The
              views and opinions contained in the testimonials belong solely to the individual user and do not reflect
              our views and opinions. <span style="color: rgb(89, 89, 89); text-align: justify;">We are not affiliated
                with users who provide testimonials, and users are not paid or otherwise compensated for their
                testimonials.</span></span>
          </div>
        </div>
        <div style="line-height: 1.2;">
          <br>
        </div>
        <div>
          <span style="font-size: 15px;"><span style="font-size: 15px;"></span></span>
          <div class="MsoNormal">
            <br>
          </div>
        </div>

      </div>
    </div>
  </div>