import { Image } from './image';
import { Product } from './product';
import { applyGalleryItemPriceFormatting, applyImageFormatting } from '../utils';
import HasImage from '../interfaces/HasImage';
import Thumbnail from '../interfaces/Thumbnail';

export class GalleryThumbnail implements HasImage, Thumbnail {
  code: string;
  collection: string;
  type: string;
  status: string;
  title: string;
  featured?: boolean;
  price: number;
  image?: Image;
  images?: Array<Image>;
  stockItem?: Product;
  slug: string;

  formattedStandardPrice?: string;
  formattedPrice?: string;
  // View properties:
  wholeSalePrice?: number;
  wholeSalePriceInEuros?: number;
  safeProductUrl?: string;
  safeOriginalUrl?: string;
  safeImageUrl?: string;
  gallery?: string;
  children?: boolean;
  ladies?: boolean;
  gents?: boolean;
  unisex?: boolean;

  static enrichGalleryItem(galleryItem: GalleryThumbnail): void {
    applyGalleryItemPriceFormatting(galleryItem);
    applyImageFormatting(galleryItem);
    galleryItem.safeProductUrl = `/product/${galleryItem.stockItem.slug}`;
  }
}
