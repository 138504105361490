import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { ringSizes } from '../../app.config';
import { CartItem } from '../../model/cartItem';
import { Product } from '../../model/product';
import { AuthService } from '../../services/auth.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { FavouritesService } from '../../services/favourites.service';
import { ProductService } from '../../services/product.service';
import { TitleService } from '../../services/title.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { finalize, share, tap } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  product$: Observable<Product>;
  cachedProduct$: Observable<Product>;
  product: Product;

  ringSizes: Array<string> = ringSizes.ireland;
  ringSize = 'M';

  subscription: Subscription;
  productSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private productService: ProductService,
    private titleService: TitleService,
    public auth: AuthService,
    public shoppingCartService: ShoppingCartService,
    public favouritesService: FavouritesService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe(routeParams => {
      this.product$ = this.fetchCachedProduct(routeParams);
      this.productSubscription = this.product$.subscribe(product => {
        this.titleService.set(product.title);
        this.breadcrumbService.setGallery(product.collection, product.type);
        this.headService.createCanonicalLink(product.safeProductUrl);
        this.headService.setMetaDescription(`${product.title} - ${product.description}`);
        this.headService.setProductStructuredData(product);
        this.headService.setFacebookMetaTags(product);
        this.headService.setTwitterMetaTags(product);
      }, () => {
        this.router.navigate(['/404']);
      });
    });
    this.favouritesService.load();
  }

  fetchProduct(routeParams): Observable<Product> {
    if(routeParams.slug) {
      console.log('Fetching product by slug');
      return this.productService.fetch(routeParams.slug);
    } else if(routeParams.code) {
      console.log('Fetching product by code');
      return this.productService.fetchByCode(routeParams.code);
    }
  }

  fetchCachedProduct(routeParams): Observable<Product> {
    let observable: Observable<Product>;
    if (this.product) {
      observable = of(this.product);
    }  else if (this.cachedProduct$) {
      observable = this.cachedProduct$;
    } else {
      this.cachedProduct$ = this.fetchProduct(routeParams)
        .pipe(
          tap(res => this.product = res),
          share(),
          finalize(() => this.cachedProduct$ = null)
        );
        observable = this.cachedProduct$;
    }
    return observable;
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    if(this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }

  addToCart(product: Product): void {
    const cartItem = new CartItem(product.code, product);
    if(product.isRing) {
      cartItem.ringSize = this.ringSize;
    }
    this.shoppingCartService.addToCart(cartItem);
  }
}
