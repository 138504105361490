import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sortBy as _sortBy, each as _each } from 'lodash-es';
import { Order } from '../../model/order';
import { orderMode, orderStatus } from '../../app.config';
import { AdminOrderService } from '../../services/admin-order.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersComponent implements OnInit, OnDestroy {

  orderList$: Observable<Order[]>;
  orderMode: any = orderMode;
  orderModeList: Array<string> = _sortBy(Object.keys(orderMode));
  orderStatus: any = orderStatus;
  orderStatusList: Array<string> = _sortBy(Object.keys(orderStatus));

  constructor(
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public orderService: AdminOrderService,
    private toasterService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.render();
  }

  paymentIntentUrl(pi): string {
    if(pi.livemode) {
      return `https://dashboard.stripe.com/payments/${pi.id}`;
    } else {
      return `https://dashboard.stripe.com/test/payments/${pi.id}`;
    }
  }

  render(): void {
    this.titleService.set('Orders');
    this.breadcrumbService.set('Orders');
    this.orderList$ = this.fetch();
  }

  fetch(): Observable<Order[]> {
    console.log(`admin-order fetch`);
    return this.orderService.fetch().pipe(
      tap((data: any) => {
        data.forEach(order => {
          order.isCollapsed = true;
          Order.enrich(order);
        });
      })
    );
  }

  updateOrderStatus(order: Order, status: string): void {
    order.status = status;
    this.orderService.updateOrder(order).subscribe(() => {
      this.toasterService.success('Order updated.', 'Successful!');
    });
  }

  ngOnDestroy(): void {
    // empty for now
  }
}
