<app-breadcrumb></app-breadcrumb>

<div class="container page cart" *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart">
  <div class="row header">
    <div class="col-12">
      <h1>Your cart total is {{shoppingCart.formattedTotalAmount}}</h1>
      <p>Free delivery anywhere in the world by DHL or Fedex.</p>
    </div>
    <div class="col-12 col-md-6 offset-md-3">
      <button class="btn btn-primary btn-block" routerLink="/checkout" [disabled]="shoppingCart.totalAmount == 0">Check
        Out</button>
    </div>
  </div>
  <div class="row item" *ngFor="let item of shoppingCart.items; let i = index">
    <div class="col-12 col-md-4 offset-lg-2 col-lg-3">
      <div class="cartImage">
        <a [routerLink]="item.product.safeProductUrl">
          <img [src]="item.product.safeImageUrl" [alt]="item.product.title" [appImgSrcSet]="item.product.originalImageUrl" sizes="(max-width: 479px): 100vw, (max-width: 767px): 390px, (max-width: 991px): 200px, (max-width: 1199px): 205px, 255px">
        </a>
      </div>
    </div>
    <div class="col-12 col-md-8 col-lg-5">
      <h2>{{item.product.title}}</h2>
      <app-ring-size-selector [ring]="item"></app-ring-size-selector>
      <div class="price" *ngIf="item.product.metadata.saleActive">
        <span class="standard-price">{{item.product.formattedStandardPrice}}</span>
        <span class="sale-price"> {{item.product.formattedPrice}}</span>
      </div>
      <div class="price" *ngIf="!item.product.metadata.saleActive">
        <div>{{item.product.formattedPrice}}</div>
      </div>
      <div class="remove">
        <a href="javascript:void(0)" (click)="removeFromCart(item)">Remove</a>
      </div>
    </div>
  </div>
  <div class="row summary">
    <div class="col-12">
      <div class="summary-row">
        <div class="label">Shipping</div>
        <div class="value">Included</div>
      </div>
      <div class="summary-row">
        <div class="label">Tax / Customs Duty</div>
        <div class="value">Included</div>
      </div>
      <div class="summary-row total">
        <div class="label">Total</div>
        <div class="value">{{shoppingCart.formattedTotalAmount}}</div>
      </div>
      <div class="tax-info">
        <p>Any applicable VAT, Customs Duty and Import Tax has been included.</p>
      </div>
    </div>
    <div class="col-12 col-md-6 offset-md-3">
      <button class="btn btn-primary btn-block" routerLink="/checkout" [disabled]="shoppingCart.totalAmount == 0">Check
        Out</button>
    </div>
  </div>
</div>