import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Product } from '../model/product';
import { PRODUCT } from '../mock/productMock';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { applyPriceFormatting } from '../utils';

@Injectable({ providedIn: 'root' })
export class ProductService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  url = (slug): string => `${environment.api}/v1/product/${slug}`;
  productByCodeUrl = (code): string => `${environment.api}/v1/product/code/${code}`;

  fetch(slug): Observable<Product> {
    const url = this.url(slug);
    const result = this.http.get<Product>(url).pipe(
      tap((product: Product) => {
        Product.enrich(product);
      })
    );
    this.googleAnalyticsService.emitEvent('view_item', 'engagement');
    return result;
  }

  fetchByCode(code): Observable<Product> {
    const url = this.productByCodeUrl(code);
    const result = this.http.get<Product>(url).pipe(
      tap((product: Product) => {
        Product.enrich(product);
      })
    );
    this.googleAnalyticsService.emitEvent('view_item', 'engagement');
    return result;
  }
}
