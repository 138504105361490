import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../model/product';
import { Image } from '../model/image';
import { environment } from '../../environments/environment';
import { convertToQueryString, applyImageFormatting } from '../utils';

@Injectable({ providedIn: 'root' })
export class ImageListService {

  fetchParams: Record<string, string | number | boolean>;
  imagelist$: Observable<Image[]>;

  constructor(private http: HttpClient, private toasterService: ToastrService) { }

  url = (params: Record<string, string | number | boolean>): string => `${environment.api}/v1/images?${convertToQueryString(params)}`;
  imageUrl = (id: number): string => `${environment.api}/v1/images/${id}`;

  fetch(params: Record<string, string | number | boolean>): Observable<Image[]> {
    this.fetchParams = params;
    console.log(`image-list.service.fetch: ${JSON.stringify(params)}`);
    this.imagelist$ = this.http.get<Product[]>(this.url(params)).pipe(
      share(),
      tap((data: any) => {
        data.metadata.pages = Array(Math.ceil(data.metadata.totalCount / data.metadata.pageSize));
      })
    );
    return this.imagelist$;
  }

  refetch(): void {
    console.log(`image-list.service.refetch: ${JSON.stringify(this.fetchParams)}`);
    this.fetch(this.fetchParams);
  }

  deleteImage(image: any): Subscription {
    console.log(`image-list.service.deleteImage: ${JSON.stringify(image)}`);
    return this.http.delete(this.imageUrl(image.id))
      .subscribe(data => {
        this.toasterService.success('Image deleted.', 'Successful!');
      }, () => {
      });
  }

  saveImage(image: any): Subscription {
    console.log(`image-list.service.saveImage: ${JSON.stringify(image)}`);
    return this.http.put<Product>(this.imageUrl(image.id), image)
      .subscribe(data => {
        this.toasterService.success('Image saved.', 'Successful!');
      }, () => {
      });
  }
}
