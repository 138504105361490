import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../model/product';
import { environment } from '../../environments/environment';
import { convertToQueryString } from '../utils';

@Injectable({ providedIn: 'root' })
export class SearchService {

  results$: Observable<Product[]>;
  term$ = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
    private toasterService: ToastrService,
    public router: Router,
  ) { }

  url = (params: Record<string, string | number | boolean>): string =>
    `${environment.api}/v1/search/products?${convertToQueryString(params)}`;

  search(term: string): Observable<Product[]> {
    console.log(`search.service.fetch: ${term}`);
    this.term$.next(term);
    this.results$ = this.http.get<Product[]>(this.url({ term })).pipe(
      share(),
      tap((data: any) => {
        data.result.forEach(product => {
          Product.enrich(product);
        });
        data.metadata.pages = Array(Math.ceil(data.metadata.totalCount / data.metadata.pageSize));
      })
    );
    return this.results$;
  }

  navigate(term: string): void {
    this.router.navigateByUrl(`/search?term=${encodeURIComponent(term)}`);
  }

}
