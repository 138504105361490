import { Component, OnInit } from '@angular/core';
import { CookiePolicyService } from '../../services/cookie-policy.service';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {

  constructor(public cookiePolicyService: CookiePolicyService) {
  }

  ngOnInit(): void {
  }

  accept(): void {
    this.cookiePolicyService.accept();
  }
}
