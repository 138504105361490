<div class="row stock-item">
  <div class="col-12">
    <div class="row">
      <div class="col-2 centered">
        <a [routerLink]="stockItem.safeProductUrl" target=”_blank”>
          <span class="stockCode"><b>{{stockItem.code}}</b></span><br /><br />
          <span class="stockImage"><img [src]="stockItem.safeImageUrl"></span>
        </a>
        <br />
        <br />
        <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block">
          <button class="btn btn-outline-primary" ngbDropdownToggle
            (click)="imageSearch = stockItem.code; onImageSearchChange(imageSearch)">Select
            Image</button>
          <div ngbDropdownMenu class="image-picker">
            <div class="row">
              <div class="col-md-6">
                <input type="text" class="form-control" [(ngModel)]="imageSearch"
                  (ngModelChange)="onImageSearchChange($event)" name="image-search-{{index}}" (change)="changed = true"
                  placeholder="Start typing a stock code..."><br />
              </div>
            </div>
            <div *ngIf="imageSearch.length > 0">
              <div class="row image-results fade-in" *ngIf="stockListService.images$ | async as images; else imagesLoading">
                <div *ngIf="images.result.length > 0; then imageResults else imagesNotFound"></div>

                <ng-template #imageResults>
                  <div class="col-md-3" *ngFor="let image of images.result">
                    <span class="stockCode"><b>{{image.fileName}}</b></span><br />
                    <span class="stockImage"><img [src]="image.safeImageUrl"
                        (click)="linkImageToItem(image, stockItem); myDrop.close()" class="img-thumbnail"></span>
                  </div>
                </ng-template>

                <ng-template #imagesNotFound>
                  <div class="row not-found-message">
                    <p>Matching images not found</p>
                  </div>
                </ng-template>

              </div>

              <ng-template #imagesLoading>
                <div class="row loading-message">
                  <div style="width: 100%"><i class="fas fa-spinner fa-5x fa-spin"></i></div>
                </div>
              </ng-template>

            </div>

            <div class="row initial-message" *ngIf="imageSearch.length === 0">
              <p>Type something!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-md-4">
            <p><b>Collection</b></p>
            <div class="form-group">
              <select class="form-control" id="gallery-{{index}}" [(ngModel)]="stockItem.gallery" name="gallery-{{index}}"
                (change)="changed = true">
                <option *ngFor="let g of galleryList" [value]="g">{{collections[g].label}}</option>
              </select>
            </div>
            <p><b>Status</b></p>
            <div class="form-group">
              <select class="form-control" id="status-{{index}}" required [(ngModel)]="stockItem.status"
                name="status-{{index}}" (change)="changed = true">
                <option *ngFor="let s of statuses" [value]="s">{{s}}</option>
              </select>
            </div>
            <p><b>On Sale?</b></p>
            <div class="row">
              <div class="col-md-6" style="text-align: right">
                <input id="on-sale-yes-{{index}}" class="radio-custom form-check-input" [(ngModel)]="stockItem.onSale"
                  name="on-sale-{{index}}" type="radio" [value]="true" (change)="changed = true">
                <label for="on-sale-yes-{{index}}" class="radio-custom-label form-check-label">Yes</label>
              </div>
              <div class="col-md-6">
                <input id="on-sale-no-{{index}}" class="radio-custom form-check-input" [(ngModel)]="stockItem.onSale"
                  name="on-sale-{{index}}" type="radio" [value]="false" (change)="changed = true">
                <label for="on-sale-no-{{index}}" class="radio-custom-label form-check-label">No</label>
              </div>
            </div>
            <p><b>Is Primary Item?</b></p>
            <div class="row">
              <div class="col-md-6" style="text-align: right">
                <input id="is-primary-yes-{{index}}" class="radio-custom form-check-input" [(ngModel)]="stockItem.isPrimary"
                  name="is-primary-{{index}}" type="radio" [value]="true" (change)="changed = true">
                <label for="is-primary-yes-{{index}}" class="radio-custom-label form-check-label">Yes</label>
              </div>
              <div class="col-md-6">
                <input id="is-primary-no-{{index}}" class="radio-custom form-check-input" [(ngModel)]="stockItem.isPrimary"
                  name="is-primary-{{index}}" type="radio" [value]="false" (change)="changed = true">
                <label for="is-primary-no-{{index}}" class="radio-custom-label form-check-label">No</label>
              </div>
            </div>

            <p *ngIf="stockItem.relatedStockItems"><b>Related Items: {{stockItem.relatedStockItems.length}}</b></p>
            <div class="row" *ngIf="stockItem.relatedStockItems">
              <div class="col">
                <p *ngFor="let relatedStockItem of stockItem.relatedStockItems; let i = index">
                  <a [routerLink]="relatedStockItem.safeProductUrl" target=”_blank”>
                    <span class="stockCode">{{relatedStockItem.code}}</span>
                  </a>
                </p>
              </div>
            </div>

          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-8">
                <p><b>Title</b> <i class="warning" *ngIf="stockItem.title.length > 55">(Too long!)</i></p>
                <input type="text" class="form-control" [(ngModel)]="stockItem.title" name="title-{{index}}"
                  (change)="changed = true;" placeholder="Title"><br />
              </div>
              <div class="col-md-4">
                <p><b>Supplier</b></p>
                <div class="form-group">
                  <select class="form-control" id="supplier-{{index}}" [(ngModel)]="stockItem.supplier"
                    name="supplier-{{index}}" (change)="changed = true">
                    <option *ngFor="let su of suppliers" [value]="su">{{su}}</option>
                  </select>
                </div>
              </div>
            </div>
            <p><b>Description</b> <i class="warning" *ngIf="stockItem.description.length < 60">(Too Short!)</i><i class="warning" *ngIf="stockItem.description.length > 160">(Too long!)</i></p>
            <textarea class="form-control" rows="3" [(ngModel)]="stockItem.description" (change)="changed = true"
              name="description-{{index}}" placeholder="Description... make this as detailed as possible."></textarea><br />
            <p><b>Supplier's Description</b></p>
            <span><b>{{stockItem.originalDescription || 'Not provided'}}</b></span><br /><br />
            <p><b>Notes</b></p>
            <textarea class="form-control" rows="2" [(ngModel)]="stockItem.notes" (change)="changed = true"
              name="notes-{{index}}" placeholder="Internal notes about the product can be entered here..."></textarea><br />
          </div>
        </div>
      </div>
      <div class="col-2">
        <p><b>Ladies or Gents?</b></p>
        <input id="ladies-{{index}}" class="checkbox-custom form-check-input" [(ngModel)]="stockItem.ladies"
          name="ladies-{{index}}" (change)="changed = true" type="checkbox">
        <label for="ladies-{{index}}" class="checkbox-custom-label form-check-label">Ladies</label><br />
        <input id="gents-{{index}}" class="checkbox-custom form-check-input" [(ngModel)]="stockItem.gents"
          name="gents-{{index}}" (change)="changed = true" type="checkbox">
        <label for="gents-{{index}}" class="checkbox-custom-label form-check-label">Gents</label><br />
        <input id="child-{{index}}" class="checkbox-custom form-check-input" [(ngModel)]="stockItem.children"
          name="children-{{index}}" (change)="changed = true" type="checkbox">
        <label for="child-{{index}}" class="checkbox-custom-label form-check-label">Children</label><br />
        <input id="unisex-{{index}}" class="checkbox-custom form-check-input" [(ngModel)]="stockItem.unisex"
          name="unisex-{{index}}" (change)="changed = true" type="checkbox">
        <label for="unisex-{{index}}" class="checkbox-custom-label form-check-label">Unisex</label><br />

        <p><b>Wholesale Price</b></p>
        <input type="text" class="form-control" currencyMask [(ngModel)]="stockItem.wholeSalePriceInEuros"
          name="whole_sale_price-{{index}}" (focusout)="changed = true" placeholder="Wholesale Price"><br />
        <p><b>Retail Price</b></p>
        <input type="text" class="form-control" currencyMask [(ngModel)]="stockItem.priceInEuros" name="price-{{index}}"
          (focusout)="changed = true" placeholder="Retail Price"><br />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="controls">
          <ng-template #popContent>
            <div class="cloned-item-code">
              <input type="text" name="cloned-item-code" class="form-control" placeholder="New Stock Code..." ngbAutofocus [(ngModel)]="newStockItemCode">
              <button class="btn btn-block btn-outline-warning" (click)="clone(newStockItemCode, stockItem); p.close()">Clone Item</button>
            </div>
          </ng-template>
          <ng-template #mymodal let-modal>
            <div class="modal-header">
              <h4 class="modal-title">Delete Stock Item?</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete stock item <b>{{stockItem.code}}</b>? This can't be undone.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
              <button type="button" ngbAutofocus class="btn btn-danger" (click)="delete(stockItem); modal.close('Delete click')">Delete</button>
            </div>
          </ng-template>
          <button class="btn btn-outline-warning" type="button" [autoClose]="'outside'" #p="ngbPopover" [ngbPopover]="popContent" popoverTitle="New Stock Item Code">Clone</button>
          <button class="btn btn-outline-danger" type="button" (click)="openDeleteModal(mymodal)">Delete</button>
          <button class="btn btn-primary" type="button" (click)="saveChanges(stockItem)">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>