import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appImgSrcSet]'
})
export class ImgSrcSetDirective implements OnInit {
  @Input() appImgSrcSet: string;
  element: ElementRef;
  imageSizes = ['100w', '200w', '400w', '800w', '1200w', '1600w', '2400w', '3200w'];
  sizeMap = {
    '100w': '?tr=w-100',
    '200w': '?tr=w-200',
    '400w': '?tr=w-400',
    '800w': '?tr=w-800',
    '1200w': '?tr=w-1200',
    '1600w': '?tr=w-1600',
    '2400w': '?tr=w-2400',
    '3200w': '?tr=w-3200',
  };
  constructor(element: ElementRef) {
    this.element = element;
  }

  ngOnInit(): void {
    const srcSetList = [];
    this.imageSizes.forEach((size) => {
      srcSetList.push(`${this.appImgSrcSet}${this.sizeMap[size]} ${size}`);
    });
    this.element.nativeElement.setAttribute('srcset', srcSetList.join(','));
  }

}
