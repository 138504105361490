import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AdminOverviewService {

  constructor(private http: HttpClient) { }

  url = (): string => `${environment.api}/v1/admin/settings`;

  fetch(): Promise<any> {
    // this.settings$ = this.http.get<any[]>(this.url()).pipe(
    //   share(),
    //   tap((data: any) => {
    //     data.result.forEach(setting => {
    //     });
    //   })
    // );
    return this.http.get<any>(this.url()).toPromise();
  }

  saveSetting(name: string, value: any): Observable<any> {
    return this.http.put<any>(this.url(), {name, value});
  }
}
