import { Component, OnInit } from '@angular/core';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private headService: HeadService,
    private titleService: TitleService,
    ) { }

  ngOnInit(): void {
    const title = 'Irish Celtic Jewellery';
    this.titleService.set(title, false);
    this.headService.createCanonicalLink(environment.root);
    this.headService.setMetaDescription(`Welcome to Irish Celtic Jewellery. 
    Handcrafted celtic wedding rings, diamond engagement rings and stylish modern designs.`);
    this.headService.setOrgStructuredData();
  }
}
