import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Order } from '../model/order';

@Injectable({ providedIn: 'root' })
export class AdminOrderService {

  orderList$: Observable<Order[]>;

  constructor(private http: HttpClient) { }

  url = (): string => `${environment.api}/v1/admin/orders`;
  orderUrl = (orderNumber: string): string => `${environment.api}/v1/admin/orders/${orderNumber}`;

  fetch(): Observable<Order[]> {
    this.orderList$ = this.http.get<any[]>(this.url()).pipe(
      share(),
      tap((data: any) => {
        data.forEach(order => {
          Order.enrich(order);
        });
      })
    );
    // return this.http.get<any>(this.url()).toPromise();
    return this.orderList$;
  }

  fetchOrder(orderNumber: string): Observable<Order> {
    const url = this.orderUrl(orderNumber);
    const result = this.http.get<Order>(url).pipe(
      tap((order: Order) => {
        Order.enrich(order);
      })
    );
    // return this.http.get<any>(this.url()).toPromise();
    return result;
  }

  updateOrder(order: Order): Observable<any> {
    return this.http.put<any>(this.orderUrl(order.orderNumber), order);
  }
}
