import { each as _each, map as _map, compact as _compact } from 'lodash-es';
import { countries, countryMap, states } from './static-data';
import { environment } from '../../environments/environment';
import HasImage from '../interfaces/HasImage';

const sleep = (ms: number): Promise<void> => new Promise(res => setTimeout(res, ms));

const formatPriceUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const formatPriceEUR = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' });
const formatPriceGBP = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'GBP' });

const formatPrice = (price: number, currency = 'eur'): string => {
  if (currency === 'eur') {
    return formatPriceEUR.format(price / 100);
  } else if (currency === 'usd') {
    return formatPriceUSD.format(price / 100);
  } else if (currency === 'gbp') {
    return formatPriceGBP.format(price / 100);
  }
  return undefined;
};

const applyImageFormatting = (product: HasImage): void => {
  let safeImageUrl;
  let safeLargeImageUrl;
  let originalImageUrl;
  if (product.image) {
    originalImageUrl = `${environment.image.root}${product.image.fileName}`;
    safeImageUrl = `${environment.image.root}${product.image.fileName}${environment.image.thumbnail}`;
    safeLargeImageUrl = `${environment.image.root}${product.image.fileName}${environment.image.large}`;
  } else if (product.images && product.images.length > 0) {
    originalImageUrl = `${environment.image.root}${product.images[0].fileName}`;
    safeImageUrl = `${environment.image.root}${product.images[0].fileName}${environment.image.thumbnail}`;
    safeLargeImageUrl = `${environment.image.root}${product.images[0].fileName}${environment.image.large}`;
  } else {
    originalImageUrl = `${environment.image.root}/assets/images/image_placeholder.png`;
    safeImageUrl = `${environment.image.root}/assets/images/image_placeholder.png${environment.image.thumbnail}`;
    safeLargeImageUrl = `${environment.image.root}/assets/images/image_placeholder.png${environment.image.large}`;
    // safeImageUrl = `${environment.root}/assets/images/image_placeholder.png`;
    // safeLargeImageUrl = `${environment.root}/assets/images/image_placeholder.png`;
  }
  product.originalImageUrl = originalImageUrl;
  product.safeImageUrl = safeImageUrl;
  product.safeLargeImageUrl = safeLargeImageUrl;
  product.safeBackgroundImageUrl = `url(${safeImageUrl})`;
  product.safeBackgroundLargeImageUrl = `url(${safeLargeImageUrl})`;
};

const applyPriceFormatting = (data: any, currency = 'eur'): void => {
  data.metadata = data.metadata || {};
  if(data.metadata.saleActive) {
    data.formattedStandardPrice = formatPrice(data.price, currency);
    data.formattedPrice = formatPrice(data.metadata.salePrice, currency);
  } else {
    data.formattedPrice = formatPrice(data.price, currency);
  }
};

const applyGalleryItemPriceFormatting = (data: any, currency = 'eur'): void => {
  const stockItem = data.stockItem;
  data.metadata = stockItem.metadata || {};
  if(data.metadata.saleActive) {
    data.formattedStandardPrice = formatPrice(stockItem.price, currency);
    data.formattedPrice = formatPrice(stockItem.metadata.salePrice, currency);
  } else {
    data.formattedPrice = formatPrice(stockItem.price, currency);
  }
};

const formatPercent = (percentDecimal: number): string => {
  const ret = `${Math.round(percentDecimal * 100)}%`;
  return ret;
};

const encodeSex = (gents: boolean, ladies: boolean, children: boolean, unisex: boolean): string => _compact([
  gents && 'gents',
  ladies && 'ladies',
  children && 'children',
  unisex && 'unisex'
]).join(',');

const convertToQueryString = (params: Record<string, string | number | boolean>): string =>
  Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');

const firstLine = (text: string): string => (text || '').split('\n')[0];

export {
  applyImageFormatting,
  applyPriceFormatting,
  applyGalleryItemPriceFormatting,
  convertToQueryString,
  encodeSex,
  firstLine,
  formatPercent,
  formatPrice,
  sleep,
};

export { countries, countryMap, states };
