import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { NgbCollapseModule, NgbDropdownModule, NgbNavModule, NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ToastrModule } from 'ngx-toastr';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AboutUsComponent } from './components/about-us/about-us.component';
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import { AdminOverviewComponent } from './components/admin-overview/admin-overview.component';
import { BreadCrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CallbackComponent } from './components/callback/callback.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { CookiePopupComponent } from './components/cookie-popup/cookie-popup.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { EditStockItemComponent } from './components/edit-stock-item/edit-stock-item.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
import { FooterComponent } from './components/footer/footer.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { ManageStockItemComponent } from './components/manage-stock-item/manage-stock-item.component';
import { MarketingBoxesComponent } from './components/marketing-boxes/marketing-boxes.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProductComponent } from './components/product/product.component';
import { ReturnsPolicyComponent } from './components/returns-policy/returns-policy.component';
import { RingSizeSelectorComponent } from './components/ring-size-selector/ring-size-selector.component';
import { SearchComponent } from './components/search/search.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { ShopMenuComponent } from './components/shop-menu/shop-menu.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { StockListComponent } from './components/stock-list/stock-list.component';
import { TermsComponent } from './components/terms/terms.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TrustPilotComponent } from './components/trust-pilot/trust-pilot.component';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { ImgSrcSetDirective } from './directives/img-src-set.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { VersionCheckService } from './services/version-check.service';
import { AccessTokenInterceptor } from './utils/http-interceptor';

const currencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '€ ',
  suffix: '',
  thousands: ',',
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    AboutUsComponent,
    AdminOrdersComponent,
    AdminOverviewComponent,
    AppComponent,
    BreadCrumbComponent,
    CallbackComponent,
    CheckoutComponent,
    ContactUsComponent,
    CookiePolicyComponent,
    CookiePopupComponent,
    DisclaimerComponent,
    EditStockItemComponent,
    FavouritesComponent,
    FooterComponent,
    GalleryComponent,
    HeaderComponent,
    HomeComponent,
    ImageListComponent,
    ManageStockItemComponent,
    MarketingBoxesComponent,
    MobileMenuComponent,
    PageNotFoundComponent,
    PaginationComponent,
    PrivacyComponent,
    ProductComponent,
    ReturnsPolicyComponent,
    RingSizeSelectorComponent,
    SearchComponent,
    SearchResultComponent,
    ShopMenuComponent,
    ShoppingCartComponent,
    StockListComponent,
    TermsComponent,
    ThumbnailComponent,
    TopBarComponent,
    TrustPilotComponent,
    YoutubePlayerComponent,

    ImgSrcSetDirective,
    ScrollSpyDirective,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'icj-server-app' }),
    FormsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/v1/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.INFO,
      disableConsoleLogging: true
    }),
    NgbCollapseModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgxCurrencyModule.forRoot(currencyMaskConfig),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
    }),
    TransferHttpCacheModule,
    YouTubePlayerModule,
  ],
  providers: [
    AuthService,
    CurrencyPipe,
    GoogleAnalyticsService,
    Meta,
    PercentPipe,
    VersionCheckService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export default AppModule;
