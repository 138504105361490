<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container" *ngIf="orderList$ | async as orderList">
    <div class="row list-head">
      <div class="chevron"> </div>
      <div class="col-2">Date of Purchase</div>
      <div class="col">Order #</div>
      <div class="col">Mode</div>
      <div class="col-2">Total Amount</div>
      <div class="col-2">Purchased By</div>
      <div class="col">Country</div>
      <div class="col">Status</div>
    </div>
    <section class="row order-section" *ngFor="let order of orderList; let i = index">
      <div class=col-12>
        <div class="row list-body">
          <div class="chevron">
            <span><i class="fas fa-chevron-down clickable" *ngIf="order.isCollapsed" (click)="order.isCollapsed = !order.isCollapsed"></i></span>
            <span><i class="fas fa-chevron-up clickable" *ngIf="!order.isCollapsed" (click)="order.isCollapsed = !order.isCollapsed"></i></span>
          </div>
          <div class="col-2">
            {{order.formattedTimeOfPurchase}}
          </div>
          <div class="col">{{order.orderNumber || 'n/a'}}</div>
          <div class="col">
            <span *ngIf="order.paymentIntent.stripe.livemode === false" class="test-order">Test</span>
            <span *ngIf="order.paymentIntent.stripe.livemode === true" class="live-order">Live</span>
          </div>
          <div class="col-2">{{order.formattedTotalAmount}}</div>
          <div class="col-2">{{order.billingName}}</div>
          <div class="col">{{order.formattedBillingCountry}}</div>
          <div class="col"><span class="status" [ngClass]="{ 'processing': order.status === 'processing' }">{{order.status}}</span></div>
        </div>
        <div class="row">
          <div class="col-12">
            <section [(ngbCollapse)]="order.isCollapsed" class="order-section">
              <div class="row order-detail">
                <div class="col-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Shipping Details</h5>
                      <dl class="row">
                        <dt class="col-4">Name</dt>
                        <dd class="col-8">{{order.shippingName}}</dd>
                        <dt class="col-4">Address</dt>
                        <dd class="col-8 address">
                          <span *ngIf="order.shippingAddress_1">{{order.shippingAddress_1}}<br></span>
                          <span *ngIf="order.shippingCity">{{order.shippingCity}}<br></span>
                          <span *ngIf="order.shippingZip">{{order.shippingZip}}<br></span>
                          <span *ngIf="order.shippingState">{{order.shippingState}}<br></span>
                          <span *ngIf="order.shippingCountry">{{order.shippingCountry}}<br></span>
                        </dd>
                        <dt class="col-4">Phone</dt>
                        <dd class="col-8">{{order.shippingPhone}}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Billing Details</h5>
                      <dl class="row">
                        <dt class="col-4">Name</dt>
                        <dd class="col-8">{{order.billingName}}</dd>
                        <dt class="col-4">Address</dt>
                        <dd class="col-8 address">
                          <span *ngIf="order.billingAddress_1">{{order.billingAddress_1}}<br></span>
                          <span *ngIf="order.billingCity">{{order.billingCity}}<br></span>
                          <span *ngIf="order.billingZip">{{order.billingZip}}<br></span>
                          <span *ngIf="order.billingState">{{order.billingState}}<br></span>
                          <span *ngIf="order.billingCountry">{{order.billingCountry}}<br></span>
                        </dd>
                        <dt class="col-4">Phone</dt>
                        <dd class="col-8">{{order.billingPhone}}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Payment Details</h5>
                      <dl class="row">
                        <dt class="col-4">Status</dt>
                        <dd class="col-8">{{order.paymentIntent.status}}</dd>
                        <dt class="col-4">Amount</dt>
                        <dd class="col-8">{{order.paymentIntent.formattedAmount}}</dd>
                        <dt class="col-4">Paid</dt>
                        <dd class="col-8">{{order.paymentIntent.formattedAmountReceived}}</dd>
                        <dt class="col-4" *ngIf="order.hasCharge">Method</dt>
                        <dd class="col-8" *ngIf="order.hasCharge">{{order.charge.payment_method_details.type}}</dd>
                        <dt class="col-4" *ngIf="order.paidWithCard">Brand</dt>
                        <dd class="col-8" *ngIf="order.paidWithCard">{{order.charge.payment_method_details.card.brand}}</dd>
                        <dt class="col-4" *ngIf="order.paidWithCard">Last 4</dt>
                        <dd class="col-8" *ngIf="order.paidWithCard">{{order.charge.payment_method_details.card.last4}}</dd>
                        <dt class="col-4">Links</dt>
                        <dd class="col-8">
                          <a href="{{paymentIntentUrl(order.paymentIntent)}}" target="_blank"><i class="fas fa-external-link-alt clickable"></i> View on Stripe</a><br/>
                          <a href="{{paymentIntentUrl(order.paymentIntent)}}" target="_blank"><i class="fas fa-download clickable"></i> View Receipt</a><br/>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row sale-items" *ngFor="let product of order.saleItems; let j = index">
                <div class="col-8 offset-sm-2">
                  <div class="card mb-3">
                    <div class="row no-gutters">
                      <div class="col-md-4">
                        <a [routerLink]="product.safeProductUrl" target=”_blank”><img [src]="product.safeImageUrl" class="item-image"></a>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">{{product.title}}</h5>
                          <p class="card-text">{{product.description}}</p>
                          <p class="card-text" *ngIf="product.ringSize">Ring Size: {{product.ringSize}}</p>
                          <p class="card-text" *ngIf="product.notes">Note: {{product.notes}}</p>
                          <p class="card-text">{{product.formattedPrice}}</p>
                          <p class="card-text"><small class="text-muted">Original Price: {{product.formatted}}</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
