<footer>

  <!-- <div id="login-modal-popup" class="modal fade" tabindex="-1" role="dialog"></div>
  <div id="account-details-modal-popup" class="modal fade" tabindex="-1" role="dialog"></div>
  <div id="registration-modal-popup" class="modal fade" tabindex="-1" role="dialog"></div>
  <div id="ring-size-modal-popup" class="modal fade top-right" tabindex="-1" role="dialog"></div>
  <div id="add-item-modal-popup" class="modal fade" tabindex="-1" role="dialog"></div>
  <div id="edit-gallery-item-modal-popup" class="modal fade" tabindex="-1" role="dialog"></div> -->

  <!-- {{>marketingfooter}}

  {{>trustboxwidget}} -->

  <!-- START FOOTER -->

  <div class="container-fluid">

    <div class="footer-social">
      <h3>Follow Us</h3>
      <div class="row">
        <ul>
          <li><a href="https://www.facebook.com/jewelryHQ/" aria-label="Facebook" target="_blank"><i class="fab fa-facebook fa-2x"></i></a>
          </li>
          <li><a href="https://twitter.com/celticjewelryHQ" aria-label="Twitter" target="_blank"><i class="fab fa-twitter fa-2x"></i></a></li>
          <li><a href="https://www.instagram.com/irishcelticjewellery/" aria-label="Instagram" target="_blank"><i
                class="fab fa-instagram fa-2x"></i></a></li>
        </ul>
      </div>
    </div>

    <div class="row footer-country">
      <div class="col">
        <span class="flag-icon flag-icon-ie"></span> <span> Ireland</span>
      </div>
    </div>

    <div class="row footer-copyright">
      <div class="col">
        © 2020 Irish Celtic Jewellery
      </div>
    </div>

    <div class="row footer-legal">
      <ul>
        <li><a href="/privacy">Privacy</a></li>
        <li><a href="/terms">Terms &amp; Conditions</a></li>
        <li><a href="/returns">Returns Policy</a></li>
        <li><a href="/disclaimer">Disclaimer</a></li>
        <li><a href="/cookie-policy">Cookie Policy</a></li>
      </ul>
    </div>

    <div class="row footer-brand">
      <div class="col">
        A Jewellery Solutions Company
      </div>
    </div>
  </div>
  <!-- END FOOTER -->
</footer>

<!-- FACEBOOK SDK -->