<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">

    <div class="row fade-in" *ngIf="product$ | async as product">
      <div class="col-12">
        <form #stockListForm="ngForm">
          <app-edit-stock-item [stockItem]="product" [index]="1">
          </app-edit-stock-item>
        </form>
      </div>
    </div>

  </div>
</section>