import { Component, OnInit, OnDestroy } from '@angular/core';
import { split as _split } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { AdminOverviewService } from '../..//services/admin-overview.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TitleService } from '../../services/title.service';
import { collections } from '../../app.config';

@Component({
  selector: 'app-admin-overview',
  templateUrl: './admin-overview.component.html',
  styleUrls: ['./admin-overview.component.scss']
})
export class AdminOverviewComponent implements OnInit, OnDestroy {

  settings: any = {};

  constructor(
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public adminOverviewService: AdminOverviewService,
    private toasterService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    this.titleService.set('Admin Overview');
    this.breadcrumbService.set('Admin Overview');
    this.fetch();
  }

  async fetch(): Promise<void> {
    this.settings = await this.adminOverviewService.fetch();
  }

  saleOnHandler($event): void {
    this.saveSetting('saleOn', this.settings.saleOn);
  }

  salePercentHandler($event): void {
    this.saveSetting('salePercent', this.settings.salePercent);
  }

  saveSetting(name: string, value: any): void {
    this.adminOverviewService.saveSetting(name, value).subscribe(() => {
      this.toasterService.success('Setting saved.', 'Successful!');
    });
  }

  ngOnDestroy(): void {
    // empty for now
  }
}
