import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private titleService: TitleService,
  ) { }

  ngOnInit(): void {
    const title = 'Cookie Policy';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
  }
}
