import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class CookiePolicyService {

  _cookiePolicy: Record<string, unknown>;
  _showCookiePopup = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private http: HttpClient,
    private logger: NGXLogger,
  ) { }

  cookiePolicyUrl = (): string => `${environment.api}/v1/cookie-policy`;

  public get showCookiePopup(): boolean {
    return this._showCookiePopup;
  }

  accept(): void {
    this._showCookiePopup = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cookie-policy-accepted', new Date().toUTCString());
    }
    this.loadHubSpotChat();
  }

  close(): void {
    this._showCookiePopup = false;
  }

  loadHubSpotChat(): void {
      // Load HubSpot Chat
      if(window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      } else {
        window.hsConversationsOnReady = [
          () => {
            window.HubSpotConversations.widget.load();
          },
        ];
      }
  }

  check(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const cookiePolicyAccepted = localStorage.getItem('cookie-policy-accepted');
        if(!cookiePolicyAccepted) {
          this._showCookiePopup = true;
        } else {
          this.loadHubSpotChat();
        }
      }
    } catch (e) {
      this.logger.error(`Cookie Check Failed: ${e.error}`, e);
    }
  }
}
