<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="filters.code" (ngModelChange)="onModelChange($event)"
          placeholder="Filter by Stock Code"><br />
      </div>
      <div class="col-9 filters">
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('all')">Show All <i class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'all', 'fa-square-o': filters.status !== 'all'}"></i></button>
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('New')">New <i class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'New', 'fa-square-o': filters.status !== 'New'}"></i></button>
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('Pending Review')">Pending Review <i
            class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'Pending Review', 'fa-square-o': filters.status !== 'Pending Review'}"></i></button>
        <button type="button" class="btn btn-outline-success" (click)="filterStatus('Needs Work')">Needs Work <i
            class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'Needs Work', 'fa-square-o': filters.status !== 'Needs Work'}"></i></button>
        <button type="button" class="btn btn-outline-success" (click)="filterStatus('Active')">Active <i class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'Active', 'fa-square-o': filters.status !== 'Active'}"></i></button>
        <button type="button" class="btn btn-outline-secondary" (click)="filterStatus('Discontinued')">Discontinued <i
            class="fa"
            [ngClass]="{'fa-check-square': filters.status === 'Discontinued', 'fa-square-o': filters.status !== 'Discontinued'}"></i></button>
      </div>
    </div>
    <div class="row fade-in" *ngIf="stockListService.stocklist$ | async as stockList">
      <div class="col-3 filters">
        <div class="form-group">
          <label for="gallery-filter">Filter by Collection</label>
          <select class="form-control" id="gallery-filter" [(ngModel)]="filters.gallery" name="gallery-filter"
            (change)="filterGallery()">
            <option value="all-all">All</option>
            <option *ngFor="let g of galleryList" [value]="g">{{collections[g].label}}</option>
          </select>
        </div>
      </div>
      <div class="col-3 filters">
        <div class="form-group">
          <label for="supplier-filter">Filter by Supplier</label>
          <select class="form-control" id="supplier-filter" [(ngModel)]="filters.supplier" name="supplier-filter"
            (change)="fetch()">
            <option value="all">All</option>
            <option *ngFor="let su of suppliers" [value]="su">{{su}}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <label class="label-right">Total: {{stockList.metadata.totalCount}}</label>
        <nav>
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="stockList.metadata.totalCount"
            [(page)]="filters.page" [pageSize]="stockList.metadata.pageSize" (pageChange)="goToPage($event)"
            [maxSize]="4" [rotate]="true" [boundaryLinks]="true">
          </ngb-pagination>
        </nav>
      </div>
    </div>

    <ng-template #stockItemsLoading>
      <div class="row loading">
        <div style="width: 100%"><i class="fas fa-spinner fa-5x fa-spin"></i></div>
      </div>
    </ng-template>

    <div class="row fade-in" *ngIf="stockListService.stocklist$ | async as stockList">
      <div class="col-12">
        <div class="table-responsive">
          <form #stockListForm="ngForm">
            <div class="row">
              <div class="col-9">
                <h1>Stock Items</h1>
              </div>
            </div>
            <app-edit-stock-item [stockItem]="stockItem" [index]="i"
              *ngFor="let stockItem of stockList.result; let i = index">
            </app-edit-stock-item>
          </form>
        </div>
      </div>
    </div>

    <div class="row fade-in" *ngIf="stockListService.stocklist$ | async as stockList">
      <div class="col-sm-6 offset-sm-6">
        <label class="label-right">Total: {{stockList.metadata.totalCount}}</label>
        <nav>
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="stockList.metadata.totalCount"
            [(page)]="filters.page" [pageSize]="stockList.metadata.pageSize" (pageChange)="goToPage($event)"
            [maxSize]="4" [rotate]="true" [boundaryLinks]="true">
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>
</section>