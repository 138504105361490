<div class="toast cookie-popup" [ngClass]="{ 'show': cookiePolicyService.showCookiePopup}">
  <div class="toast-header">
    <strong class="mr-auto">Allow Cookies?</strong>
    <small></small>
    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast">
      <i class="fas fa-times" (click)="cookiePolicyService.close()"></i>
    </button>
  </div>
  <div class="toast-body">
    We use cookies to ensure you get the best experience on our website.<br />
    <a routerLink="/cookie-policy">Learn more about our cookies</a>
    <button type="button" class="btn btn-primary btn-block" (click)="cookiePolicyService.accept()">Allow
      Cookies</button>

  </div>
</div>