import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {

  title = '';

  constructor(private titleMeta: Title) { }

  set(title = '', append = true): void {
    const brand = title.length <= 35 ? ' | Irish Celtic Jewellery' : ' | ICJ';
    this.titleMeta.setTitle(`${title}${append ? brand : ''}`);
    this.title = title;
  }
}
