<div class="productBox">
  <a [routerLink]="product.safeProductUrl" title="{{product.title}}">
    <div class="productImage">
      <div [style.background-image]="product.safeBackgroundImageUrl" class="backgroundImage"></div>
      <div class="productMasking">
      </div>
    </div>
  </a>
  <div class="productCaption">
    <div class="row">
      <div class="col">
        <a [routerLink]="product.safeProductUrl" title="{{product.title}}">
          <h5>{{product.title}}</h5>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="price" *ngIf="product.metadata.saleActive">
          <span class="standard-price">{{product.formattedStandardPrice}}</span>
          <span class="sale-price"> {{product.formattedPrice}}</span>
        </div>
        <div class="price" *ngIf="!product.metadata.saleActive">
          <div>{{product.formattedPrice}}</div>
        </div>
      </div>
      <div class="favourites">
        <a id="add-to-favourites-{{index}}" *ngIf="!favouritesService.favouritesMap[product.code]"
          (click)="addToFavourites()">
          <i class="far fa-heart"></i>
        </a>
        <a id="remove-from-favourites-{{index}}" *ngIf="favouritesService.favouritesMap[product.code]"
          (click)="removeFromFavourites()">
          <i class="fas fa-heart"></i>
        </a>
      </div>
    </div>
  </div>
</div>