import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ringSizes } from '../../app.config';
import { CartItem } from '../../model/cartItem';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
  selector: 'app-ring-size-selector',
  templateUrl: './ring-size-selector.component.html',
  styleUrls: ['./ring-size-selector.component.scss']
})
export class RingSizeSelectorComponent implements OnInit, OnDestroy {

  @Input() ring: CartItem;
  ringSizes: Array<string> = ringSizes.ireland;
  ringSize = 'M';

  constructor(
    public shoppingCartService: ShoppingCartService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  updateShoppingCart(cartItem: CartItem): void {
    this.shoppingCartService.updateCartItem(cartItem);
  }
}
