import { Component, Input, OnInit } from '@angular/core';
import Thumbnail from '../../interfaces/Thumbnail';
import { FavouritesService } from '../../services/favourites.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  @Input() product: Thumbnail;
  @Input() index: number;

  constructor(
    public favouritesService: FavouritesService
  ) { }

  ngOnInit(): void {
    this.favouritesService.load();
  }

  addToFavourites(): void {
    this.favouritesService.add(this.product.code);
  }

  removeFromFavourites(): void {
    this.favouritesService.remove(this.product.code);
  }
}
