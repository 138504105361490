import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';


@Injectable()
export class VersionCheckService {

  private frequency = 1000 * 60 * 2; // 2 minutes
  private timeOutDuration = 1000 * 30 * 1; // 30 seconds
  private url = `${environment.root}/assets/version.json`;
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    @Inject(PLATFORM_ID) private platformID: Record<string, any>,
    private http: HttpClient,
    private toasterService: ToastrService
  ) { }

  public initVersionCheck(): void {
    if (isPlatformBrowser(this.platformID)) {
      setInterval(() => {
        this.checkVersion(this.url);
      }, this.frequency);
    }
  }

  private checkVersion(url): void {
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe((response: any) => {
        if(this.currentHash !== response.hash) {
          console.log(`Current Hash = ${this.currentHash}, New Hash = ${response.hash}. Initiating a browser reload...`);
        }
        const hashChanged = this.hasHashChanged(this.currentHash, response.hash);
        if (hashChanged) {
          this.toasterService.info(
            'A new version of the website has been released. This page will reload in 30 seconds.',
            'New UI available', {
              timeOut: this.timeOutDuration,
              progressBar: true
            }
          );
          setTimeout(() => {
            location.reload();
          }, this.timeOutDuration);
        }
        // Store the new hash so we wouldn't trigger versionChange again
        // Only necessary in case you did not force refresh
        this.currentHash = response.hash;
      }, (err) => {
        console.error(err, 'Could not get version');
      }
      );
  }

  private hasHashChanged(currentHash, newHash): boolean {
    if (!currentHash) { // No hash, e.g. during development
      return false;
    }
    return currentHash !== newHash;
  }
}
