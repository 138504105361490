<app-breadcrumb></app-breadcrumb>

<div class="container page">
  <h1>About Us</h1>
  <div class="row">
    <div class="col-12 col-lg-10 offset-lg-1">
      <div class="section">
        <div class="section-title">Our Story</div>
        <div class="section-body">
          <p class="larger">Céad míle fáilte!</p>
          <p>Hello and welcome to <em>Irish Celtic Jewellery</em>. We are a family owned business based in County
            Dublin, Ireland. </p>
          <p>Our specialty is handcrafted celtic jewellery, celtic wedding rings and diamond engagement
            rings. We also have a wide range of modern designs, such as our high end modern diamond collection, and our
            more affordable but incredibly stylish <em>House Of Lor</em> collection. </p>
          <p>Our director Rory Hayes began his career in the jewellery industry back in 1987 when he, together
            with his wife Pauline, bought <em>Malahide Jewellers</em> in the beautiful seaside town of Malahide in north
            County Dublin.</p>
          <p>In 2004 he brought his jewellery store online, with one of the first ever websites
            dedicated to selling Irish Celtic Jewellery: <a
              href="https://www.irishcelticjewellery.com">www.irishcelticjewellery.com</a>.</p>
          <p>After 20 years of great success in Malahide Jewellers, Rory and Pauline decided in 2007 to retire
            from the bricks and mortar store and to focus instead on the online business. As the business has grown,
            their son Alan and his partner Jasna have also joined the team.</p>

          <p>Nothing makes us happier than to connect with our extended Irish friends and relatives from all across the
            world, and to provide them with all the help and guidance they need when choosing a special piece of
            handcrafted irish jewellery.</p>

          <p>We have continued to evolve over the years, but we have always maintained our core focus on offering the
            very finest quality handcrafted irish celtic jewellery combined with delightful customer service.</p>

          <p>Rory takes great pride in his customer service. After more than 30 years in
            business, and 200,000 customers later, we know the value of a happy customer!</p>

          <p>We hope you enjoy our newly redesigned website, and if you have any feedback at all, we'd be delighted to
            here from you.</p>

          <p>If you have a query or would simply like to send us a comment, you can contact Rory directly by email at <a
              href="mailto:rory@irishcelticjewellery.com">rory@irishcelticjewellery.com</a>. Alternatively, you can call
            him by telephone <a href="callto:+353858666215"><i class="fas fa-phone"></i> +353 85 866 6215</a>.</p>

          <p><em>Go raibh míle maith agat!</em> (Thank you very much!)</p>
          <p class="larger">Rory &amp; Pauline</p>

        </div>
      </div>

    </div>

    <div class="col-12 col-lg-8 offset-lg-2">
      <div class="section">
        <div class="section-title">Our Promise</div>
        <div class="section-body">
          <p>Our promise to you begins before you even place an order with us.</p>
          <p>From the moment you first contact us, we promise to answer all your questions and guide you through the
            entire decision making process.</p>
          <p>Then, if and when you do place an order with us, our promise is to have our skilled craftsmen complete your
            order to the very highest standard, exactly as you wish. Your jewellery will always be handcrafted, making
            it a truly unique piece. </p>
          <p>Once it is ready, it will then be assayed, whereby the authenticity and purity of the precious metal is
            verified and discreetly stamped. </p>
          <p>We then package it in a beautiful box, and deliver anywhere in the world, to you, free of charge via Fedex.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="darkSection">

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Our People</h2>
      </div>
      <div class="col-md-4">
        <div class="thumbnail">
          <!-- <img src="img/about-us/people-01.jpg" alt="people-image"> -->
          <div class="our-people-caption">
            <h3>Rory Hayes</h3>
            <p>Director</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="thumbnail">
          <!-- <img src="img/about-us/people-02.jpg" alt="people-image"> -->
          <div class="our-people-caption">
            <h3>Alan Hayes</h3>
            <p>Full Stack Engineer</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="thumbnail">
          <!-- <img src="img/about-us/people-03.jpg" alt="people-image"> -->
          <div class="our-people-caption">
            <h3>Jasna Velickovic</h3>
            <p>UI/UX/QE Engineer</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>