import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';
import { Response } from 'express';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Optional() @Inject(RESPONSE) private response: Response,
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private titleService: TitleService,
  ) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.status(404);
    }
    const title = 'Page Not Found';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
  }
}
