import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MobileMenuService } from '../../services/mobile-menu.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {

  constructor(public auth: AuthService, public mobileMenuService: MobileMenuService) {
  }

  ngOnInit(): void {
  }
}
