import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  @Input() breadcrumb: string; // not used?
  @Input() showTitle = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
    public titleService: TitleService,
  ) { }

  ngOnInit() {
  }
}
