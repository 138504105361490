<app-breadcrumb [showTitle]="false"></app-breadcrumb>

<div class="container page" *ngIf="product$ | async as product">

  <div class="row product">
    <div class="col-12 col-lg-5">
      <h1>{{product.title}}</h1>
      <div class="price" *ngIf="product.metadata.saleActive">
        <div class="standard-price">{{product.formattedStandardPrice}}</div>
        <div class="sale-price">{{product.formattedPrice}}</div>
      </div>
      <div class="price" *ngIf="!product.metadata.saleActive">
        <div>{{product.formattedPrice}}</div>
      </div>
      <p class="code">Code: {{product.code}}</p>
      <div class="row d-none d-lg-block">
        <div class="description section">
          <div class="section-title">Description</div>
          <div class="section-body">
            <pre>{{product.description}}</pre>
          </div>
        </div>
        <div class="col actions">
          <div class="edit-item">
            <a routerLink="/admin/stock-item/{{product.code}}" *ngIf="auth.isAdmin"
              class="btn btn-outline-secondary">Edit Item <i class="fas fa-edit"></i></a>
          </div>
          <div class="favourites">
            <button type="button" class="btn btn-outline-secondary"
              *ngIf="product.galleryItem && !favouritesService.favouritesMap[product.galleryItem.code]"
              (click)="favouritesService.add(product.galleryItem.code)">Favourite
              <i class="far fa-heart"></i></button>
            <button type="button" class="btn btn-outline-secondary"
              *ngIf="product.galleryItem && favouritesService.favouritesMap[product.galleryItem.code]"
              (click)="favouritesService.remove(product.galleryItem.code)">Favourited <i
                class="fas fa-heart"></i></button>
          </div>
          <div class="add-to-cart">
            <a href="javascript:void(0)" class="btn btn-primary d-inline-block d-md-none"
              (click)="addToCart(product)">Add to Cart</a>
            <a href="javascript:void(0)" class="btn btn-primary btn-lg d-none d-md-inline-block"
              (click)="addToCart(product)">Add to Cart</a>
          </div>
        </div>
        <div class="sharebuttons">
          <div>Share with your friends</div>
          <div class="btn-group btn-group-sm">
            <a href="https://twitter.com/intent/tweet?text={{product.title}}&url={{product.safeProductFullUrl}}&via=celticJewelryHQ" class="btn btn-twitter" target="_blank"><i class="fab fa-twitter"></i></a>
            <a href="https://www.facebook.com/sharer/sharer.php?u={{product.safeProductFullUrl}}" class="btn btn-facebook" target="_blank"><i class="fab fa-facebook"></i></a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{product.safeProductFullUrl}}&title={{product.title}}&source={{product.title}}" class="btn btn-linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            <a href="https://pinterest.com/pin/create/button/?url={{product.safeProductFullUrl}}&description={{product.title}}&media={{product.safeImageUrl}}" class="btn btn-pinterest" target="_blank"><i class="fab fa-pinterest"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <div class="product-image">
        <img [src]="product.safeLargeImageUrl" [alt]="product.description" [appImgSrcSet]="product.originalImageUrl" sizes="(max-width: 479px): 100vw, (max-width: 767px): 420px, (max-width: 991px): 690px, 650px" >
      </div>
      <p class="supplier" *ngIf="product.supplier">Designed by {{product.supplier}}</p>
      <div class="row d-block d-lg-none">
        <div class="col actions">
          <div class="favourites">
            <button type="button" class="btn btn-outline-secondary"
              *ngIf="product.galleryItem && !favouritesService.favouritesMap[product.galleryItem.code]"
              (click)="favouritesService.add(product.galleryItem.code)">Favourite
              <i class="far fa-heart"></i></button>
            <button type="button" class="btn btn-outline-secondary"
              *ngIf="product.galleryItem && favouritesService.favouritesMap[product.galleryItem.code]"
              (click)="favouritesService.remove(product.galleryItem.code)">Favourited <i
                class="fas fa-heart"></i></button>
          </div>
          <div class="add-to-cart">
            <a href="javascript:void(0)" class="btn btn-primary d-inline-block d-md-none"
              (click)="addToCart(product)">Add to Cart</a>
            <a href="javascript:void(0)" class="btn btn-primary btn-lg d-none d-md-inline-block"
              (click)="addToCart(product)">Add to Cart</a>
          </div>
        </div>
        <div class="sharebuttons">
          <div>Share with your friends</div>
          <div class="btn-group btn-group-sm">
            <a href="https://twitter.com/intent/tweet?text={{product.title}}&url={{product.safeProductFullUrl}}&via=celticJewelryHQ" class="btn btn-twitter" target="_blank"><i class="fab fa-twitter"></i></a>
            <a href="https://www.facebook.com/sharer/sharer.php?u={{product.safeProductFullUrl}}" class="btn btn-facebook" target="_blank"><i class="fab fa-facebook"></i></a>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url={{product.safeProductFullUrl}}&title={{product.title}}&source={{product.title}}" class="btn btn-linkedin" target="_blank"><i class="fab fa-linkedin"></i></a>
            <a href="https://pinterest.com/pin/create/button/?url={{product.safeProductFullUrl}}&description={{product.title}}&media={{product.safeImageUrl}}" class="btn btn-pinterest" target="_blank"><i class="fab fa-pinterest"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="description section d-block d-lg-none">
        <div class="section-title">Description</div>
        <div class="section-body">
          <p>{{product.description}}</p>
        </div>
      </div>
      <div class="supplier-description section" *ngIf="auth.isAdmin">
        <div class="section-title">Supplier's Description</div>
        <div class="section-body">
          <p>{{product.originalDescription || 'Not provided.' }}</p>
        </div>
      </div>
      <div class="ring-size section" *ngIf="product.isRing">
        <div class="section-title">Ring Size</div>
        <div class="section-body">
          <p>Select a ring size:</p>
          <div class="ring-size-options">
            <label class="btn btn-outline-primary ring-size-option" *ngFor="let size of ringSizes; let i = index"
              [ngClass]="{ 'active': ringSize == size}">
              <input type="radio" name="my-ring-size" [(ngModel)]="ringSize" (click)="ringSize = size"
                autocomplete="off"> {{size}}
            </label>
          </div>
        </div>
      </div>
      <div class="related-items section" *ngIf="product.relatedStockItems && product.relatedStockItems.length > 0">
        <div class="section-title">Related Items</div>
        <div class="section-body">
          <div class="flexcontainer">
            <app-thumbnail *ngFor="let relatedStockItem of product.relatedStockItems; let i = index"
              [thumbnail]="relatedStockItem">
            </app-thumbnail>
          </div>
        </div>
      </div>
      <div class="detail section">
        <div class="section-title" data-toggle="collapse" href="#detail-section">Detail</div>
        <div class="section-body collapse show" id="detail-section">
          <pre class="description">{{product.detail || product.description}}</pre>
          <p>All our items are handcrafted by our experienced goldsmiths the moment you complete your order.
            Making each item a truly unique piece of celtic jewellery.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row product">
    <div class="col-12">
      <div class="delivery section">
        <div class="section-title">Delivery</div>
        <div class="section-body">
          <p><b>Free Delivery</b> is available on this item.</p>
          <p>Since each piece is handcrafted, it usually takes about 2 weeks before it is ready to be dispatched to you.
          </p>
        </div>
      </div>
      <div class="our-promise section">
        <div class="section-title">Our Promise To You</div>
        <div class="section-body">
          <p class="description">When you order from us, our promise is to have our skilled craftsmen complete
            your order to the very highest standard.<br /><br />
            Your jewellery will be handcrafted, making it a truly unique piece. Once it is ready, it is then
            assayed, whereby its authenticity and purity is verified and discreetly stamped.<br /><br />
            We then package it in a beautiful box, and deliver anywhere in the world, free of charge, via Fedex.
          </p>
        </div>
      </div>
      <div class="choosing-a-ring section" *ngIf="product.isRing">
        <div class="section-title">Choosing A Ring</div>
        <div class="section-body">
          <p>When choosing a ring, you must consider your ring size. For example if you choose a 3.5mm wide ring
            in a size that suits - all is good. But if you choose a ring with a wider shank, say, a 7.5mm wide
            shank, you should consider a size one up from whichever size you thought was correct.<br /><br />
            As all our wedding bands are continuous all around in design and the join is seamless, it is not
            practical to subsequently size the ring up to suit the finger - so, you must get it right first
            time.<br /><br />
            Our rings can be made from the following purities of gold:
          </p>
          <ul>
            <li>10 carat gold</li>
            <li>14 carat gold</li>
            <li>18 carat gold</li>
          </ul>
          <br />
          <p>... which can be white or yellow or rose gold.<br /><br />
            Our rings also come in platinum, palladium and silver. All are precious metals to the core and
            independently assayed in our Dublin Assay office in Dublin Castle.
          </p>
        </div>
      </div>

      <!-- <ul class="list-inline">
        <li *ngIf="breadcrumbService.safeUri !== null">
          <a [routerLink]="breadcrumbService.safeUri" routerLinkActive="active"><i class="fas fa-reply"></i>Continue
            browsing in {{breadcrumbService.breadcrumb}}</a>
        </li>
        <li><a href="#"><i class="fas fa-plus" aria-hidden="true"></i>Share This</a></li>
      </ul> -->

      <div class="promises section">
        <ul>
          <li>
            <i class="fas fa-check" aria-hidden="true"></i> <span>Free Delivery</span>
            <p>We deliver anywhere in the world free of charge.</p>
          </li>
          <li>
            <i class="fas fa-check" aria-hidden="true"></i> <span>Lowest Price Guarantee</span>
            <p>If you find the same item cheaper anywhere else, we'll beat their price.</p>
          </li>
          <li>
            <i class="fas fa-check" aria-hidden="true"></i> <span>Quality Assured</span>
            <p>The purity of all our precious metals is independently assayed by the Dublin Assay Office.</p>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>