import HasRingSize from '../interfaces/HasRingSize';
import { Product } from './product';

export class CartItem implements HasRingSize {

  id: string;
  code: string;

  product?: Product;
  ringSize?: string;
  note?: string;
  safeImageUrl?: string;
  safeProductUrl?: string;

  constructor(code: string, product?: Product, ringSize?: string, note?: string) {
    this.product = product;
    this.code = code;
    this.ringSize = ringSize;
    this.note = note;
  }
}
