<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">
    <ng-template #searchLoading>
      <div class="row loading-message">
        <div style="width: 100%"><i class="fas fa-spinner fa-5x fa-spin"></i></div>
      </div>
    </ng-template>

    <div class="row" *ngIf="searchService.results$ | async as results; else searchLoading">
      <div *ngIf="results.metadata.totalCount > 0; then searchResults else noSearchResults"></div>

      <ng-template #noSearchResults>
        <div class="col-12 not-found-message" *ngIf="results.metadata.totalCount === 0">
          <h2>No results found for "{{term}}"! <i class="far fa-frown"></i></h2>
          <p>Try searching for something else</p>
        </div>
      </ng-template>

      <ng-template #searchResults>
        <div class="col-lg-3 col-md-4" *ngFor="let product of results.result; let i = index">
          <app-search-result [product]="product" [index]="i"></app-search-result>
        </div>
      </ng-template>
    </div>
  </div>
</section>