import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare let gtag: (a,b,c) => void;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
  ) {}

  public emitEvent(action: string, category: string = undefined, value: number = null, label: string = null ): void {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value
      });
    }
  }
}
