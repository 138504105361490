import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilService {

  _isYoutubeAPILoaded = false;

  setYoutubeAPILoaded(value: boolean): void {
    this._isYoutubeAPILoaded = value;
  }

  isYoutubeAPILoaded(): boolean {
    return this._isYoutubeAPILoaded;
  }
}
