import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private titleService: TitleService,
  ) { }

  ngOnInit(): void {
    const title = 'Contact Us';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
  }
}
