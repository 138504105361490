import { Injectable } from '@angular/core';
import { collections } from '../app.config.js';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

  breadcrumb: string;
  safeUri: string;

  set(breadcrumb: string): void {
    this.breadcrumb = breadcrumb;
    this.safeUri = null;
  }

  setGallery(collection: string, type: string): void {
    const gallery = collections[`${collection}-${type}`];
    if (gallery) {
      this.breadcrumb = gallery.label;
      this.safeUri = gallery.uri;
    }
  }
}
