import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { HeadService } from '../../services/head.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { TitleService } from '../../services/title.service';
import { CartItem } from '../../model/cartItem';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

  constructor(
    private breadcrumbService: BreadcrumbService,
    private headService: HeadService,
    private titleService: TitleService,
    public shoppingCartService: ShoppingCartService,
  ) { }

  ngOnInit() {
    const title = 'Shopping Cart';
    this.titleService.set(title);
    this.breadcrumbService.set(title);
    this.headService.setMetaDescription(title);
  }

  removeFromCart(cartItem: CartItem): void {
    this.shoppingCartService.removeFromCart(cartItem);
  }

  ngOnDestroy() {
  }
}
