
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pagination = {
    page: 1,
    pageSize: 10,
    numberOfPages: 1,
    totalCount: 0,
    collectionUrl: '',
    pages: [],
    previousPage: 1,
    nextPage: 2,
  };

  /**
   * If `true`, the ellipsis symbols and first/last page numbers will be shown when `maxSize` > number of pages.
   */
  @Input() ellipses = true;

  /**
   * Whether to rotate pages when `maxSize` > number of pages.
   *
   * The current page always stays in the middle if `true`.
   */
  @Input() rotate = true;

  /**
   *  The maximum number of pages to display.
   */
  @Input() maxSize = 4;

  constructor() { }

  ngOnInit() {

    if (!this.isNumber(this.pagination.numberOfPages)) {
      this.pagination.numberOfPages = 0;
    }

    // fill-in model needed to render pages
    this.pagination.pages.length = 0;
    for (let i = 1; i <= this.pagination.numberOfPages; i++) {
      this.pagination.pages.push(i);
    }

    // set page within 1..max range
    // this._setPageInRange(newPage);

    // apply maxSize if necessary
    if (this.maxSize > 0 && this.pagination.numberOfPages > this.maxSize) {
      let start = 0;
      let end = this.pagination.numberOfPages;

      // either paginating or rotating page numbers
      if (this.rotate) {
        [start, end] = this._applyRotation();
      } else {
        [start, end] = this._applyPagination();
      }

      this.pagination.pages = this.pagination.pages.slice(start, end);

      // adding ellipses
      this._applyEllipses(start, end);
    }
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
  }

  isEllipsis(pageNumber: number): boolean {
    return pageNumber === -1;
  }

  /**
   * Appends ellipses and first/last page number to the displayed pages
   */
   private _applyEllipses(start: number, end: number) {
    if (this.ellipses) {
      if (start > 0) {
        // The first page will always be included. If the displayed range
        // starts after the third page, then add ellipsis. But if the range
        // starts on the third page, then add the second page instead of
        // an ellipsis, because the ellipsis would only hide a single page.
        if (start > 2) {
          this.pagination.pages.unshift(-1);
        } else if (start === 2) {
          this.pagination.pages.unshift(2);
        }
        this.pagination.pages.unshift(1);
      }
      if (end < this.pagination.numberOfPages) {
        // The last page will always be included. If the displayed range
        // ends before the third-last page, then add ellipsis. But if the range
        // ends on third-last page, then add the second-last page instead of
        // an ellipsis, because the ellipsis would only hide a single page.
        if (end < (this.pagination.numberOfPages - 2)) {
          this.pagination.pages.push(-1);
        } else if (end === (this.pagination.numberOfPages - 2)) {
          this.pagination.pages.push(this.pagination.numberOfPages - 1);
        }
        this.pagination.pages.push(this.pagination.numberOfPages);
      }
    }
  }

  /**
   * Rotates page numbers based on maxSize items visible.
   * Currently selected page stays in the middle:
   *
   * Ex. for selected page = 6:
   * [5,*6*,7] for maxSize = 3
   * [4,5,*6*,7] for maxSize = 4
   */
   private _applyRotation(): [number, number] {
    let start = 0;
    let end = this.pagination.numberOfPages;
    const leftOffset = Math.floor(this.maxSize / 2);
    const rightOffset = this.maxSize % 2 === 0 ? leftOffset - 1 : leftOffset;

    if (this.pagination.page <= leftOffset) {
      // very beginning, no rotation -> [0..maxSize]
      end = this.maxSize;
    } else if (this.pagination.numberOfPages - this.pagination.page < leftOffset) {
      // very end, no rotation -> [len-maxSize..len]
      start = this.pagination.numberOfPages - this.maxSize;
    } else {
      // rotate
      start = this.pagination.page - leftOffset - 1;
      end = this.pagination.page + rightOffset;
    }

    return [start, end];
  }

  /**
   * Paginates page numbers based on maxSize items per page.
   */
   private _applyPagination(): [number, number] {
    const page = Math.ceil(this.pagination.page / this.maxSize) - 1;
    const start = page * this.maxSize;
    const end = start + this.maxSize;
    return [start, end];
  }

}
