import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { cloneDeep as _cloneDeep, sortBy as _sortBy, split as _split } from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockListService } from '../..//services/stock-list.service';
import { collections } from '../../app.config';
import { encodeSex } from '../../utils';
import { Product } from '../../model/product';
import { Image } from '../../model/image';
@Component({
  selector: 'app-edit-stock-item',
  templateUrl: './edit-stock-item.component.html',
  styleUrls: ['./edit-stock-item.component.scss']
})
export class EditStockItemComponent implements OnInit, OnDestroy {

  @Input() stockItem: Product;
  @Input() index: number;
  collections = {};
  imageSearch = '';
  statuses = ['Active', 'Discontinued', 'Needs Work', 'New', 'Pending Review'];
  galleryList = _sortBy(Object.keys(collections));
  suppliers = ['Blackman', 'Ansley', 'TJH', 'n/a'];

  changed = false;

  imageSearch$ = new Subject<string>();

  constructor(
    public stockListService: StockListService,
    private modalService: NgbModal
  ) {
    this.collections = collections;
    this.imageSearch$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        console.log(`this.imageSearch = ${this.imageSearch}, searchText = ${searchText}`);
        this.imageSearch = searchText;
        return this.stockListService.fetchImages(this.imageSearch);
      })
    ).subscribe(response => {
      console.log('in imageSearch$ subscribe', response);
    });
  }

  ngOnInit(): void {
  }

  onImageSearchChange(value: string): void {
    this.imageSearch$.next(value);
  }

  linkImageToItem(image: Image, stockItem: Product): void {
    stockItem.images = [image]; // For now, totally wipe all previous image links
    this.stockListService.saveStockItem(stockItem);
  }

  openDeleteModal(content: any): void {
    this.modalService.open(content);
  }

  delete(item: any): void {
    this.stockListService.deleteStockItem(item);
  }

  clone(newItemCode: string, itemToClone: any): void {
    const clonedItem = Product.clone(itemToClone);
    clonedItem.code = newItemCode;
    this.stockListService.createStockItem(clonedItem);
  }

  saveChanges(item: any): void {
    const [collection, type] = item.gallery.split('-');
    item.collection = collection;
    item.type = type;
    item.sex = encodeSex(item.gents, item.ladies, item.children, item.unisex);
    this.stockListService.saveStockItem(item);
  }

  ngOnDestroy(): void {
    if(this.imageSearch$) {
      this.imageSearch$.unsubscribe();
    }
  }
}
