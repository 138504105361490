export const environment = {
  production: true,
  env: 'prod',
  log_level: 'info',
  name: 'icj-ui-app',
  root: 'https://www.irishcelticjewellery.com',
  api: 'https://www.irishcelticjewellery.com/api',
  image: {
    // root: 'https://ik.imagekit.io/irishcelticjewellery/images/original/',
    root: 'https://ik.imagekit.io/irishcelticjewellery/cdn/',
    thumbnail: '?tr=w-300',
    large: '?tr=w-1200'
  },
  auth0: {
    domain: 'icj.auth0.com',
    redirectUri: 'https://www.irishcelticjewellery.com/logged-in/callback',
    clientID: 'HwzADTE2XPn1jKeNK3DDUYvle8ZDaTgZ',
    responseType: 'token id_token',
    scope: 'openid profile',
    audience: 'https://www.irishcelticjewellery.com/api/',
    logoutUrl: 'https://icj.auth0.com/v2/logout?client_id=HwzADTE2XPn1jKeNK3DDUYvle8ZDaTgZ&returnTo=https://www.irishcelticjewellery.com/home'
  },
  stripe: {
    key: 'pk_live_awWBTq4BVhihaRiiMqvZg4Zd00WraMat1v'
  }
};
