
/* eslint-disable max-len */

const pages = {
  '/collection/engagement-rings/classic-solitaire-rings': {
     code:'solitaire-engagement-rings',
     title:'Solitaire Engagement Rings',
     description:'Our Solitaire Engagement Ring collection showcases an exquisite selection of rings where the solitaire diamond is front and center.'
  },
  '/collection/engagement-rings/celtic-engagement-rings':{
     code:'celtic-engagement-rings',
     title:'Celtic Engagement Rings',
     description:'Our Celtic Engagement Ring collection offers a huge selection of handcrafted celtic designs which evoke the ancient culture of Ireland.'
  },
  '/collection/wedding-bands/celtic-wedding-bands':{
     code:'celtic-wedding-bands',
     title:'Celtic Wedding Rings',
     description:'Our Celtic Wedding Rings will make your special day unforgettable. A unique and beautiful symbol of your irish heritage.'
  },
  '/collection/wedding-bands/diamond-wedding-bands':{
     code:'diamond-wedding-bands',
     title:'Diamond Wedding Bands',
     description:'Our Diamond Wedding Rings are a sight to behold. Handcrafted in Ireland, there is no better way to show your love than with a unique handmade diamond-encrusted wedding ring.'
  },
  '/collection/modern-irish/bangles':{
     code:'modern-irish-bangles',
     title:'Modern Irish Bangles',
     description:'Ultra-modern stylish bangles. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/bracelets':{
     code:'modern-irish-bracelets',
     title:'Modern Irish Bracelets',
     description:'Ultra-modern stylish bracelets. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/chains':{
     code:'modern-irish-chains',
     title:'Modern Irish Chains',
     description:'Ultra-modern stylish chains and necklaces. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/earrings':{
     code:'modern-irish-earrings',
     title:'Modern Irish Earrings',
     description:'Ultra-modern stylish earrings. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/pendants':{
     code:'modern-irish-pendants',
     title:'Modern Irish Pendants',
     description:'Ultra-modern stylish pendants. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/rings':{
     code:'modern-irish-rings',
     title:'Modern Irish Rings',
     description:'Ultra-modern stylish luxury rings. Designed and made by hand in Ireland.'
  },
  '/collection/modern-irish/watches':{
     code:'modern-irish-watches',
     title:'Modern Irish Watches',
     description:'Stylish, Elegant Irish timepieces with a Modern Twist. Designed and made in Ireland.'
  },
  '/collection/celtic/bangles':{
     code:'celtic-bangles',
     title:'Celtic Bangles',
     description:'Irish Celtic Bangles. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/bracelets':{
     code:'celtic-bracelets',
     title:'Celtic Bracelets',
     description:'Irish Celtic Bracelets. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/brooches':{
     code:'celtic-brooches',
     title:'Celtic Brooches',
     description:'Irish Celtic Brooches. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/cufflinks':{
     code:'celtic-cufflinks',
     title:'Celtic Cufflinks',
     description:'Irish Celtic Cufflinks. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/earrings':{
     code:'celtic-earrings',
     title:'Celtic Earrings',
     description:'Irish Celtic Earrings. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/pendants':{
     code:'celtic-pendants',
     title:'Celtic Pendants',
     description:'Irish Celtic Pendants. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/celtic/rings':{
     code:'celtic-rings',
     title:'Celtic Rings',
     description:'Irish Celtic Rings. Timeless, traditional, affordable designs. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/bangles':{
     code:'house-of-lor-bangles',
     title:'House of Lor Bangles',
     description:'House of Lor Bangles. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/bracelets':{
     code:'house-of-lor-bracelets',
     title:'House of Lor Bracelets',
     description:'House of Lor Bracelets. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/chains':{
     code:'house-of-lor-chains',
     title:'House of Lor Chains',
     description:'House of Lor Chains. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/earrings':{
     code:'house-of-lor-earrings',
     title:'House of Lor Earrings',
     description:'House of Lor Earrings. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/pendants':{
     code:'house-of-lor-pendants',
     title:'House of Lor Pendants',
     description:'House of Lor Pendants. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  },
  '/collection/house-of-lor/rings':{
     code:'house-of-lor-rings',
     title:'House of Lor Rings',
     description:'House of Lor Rings. Modern, stylish, affordable designs with an irish celtic twist. Made by hand in Ireland.'
  }
};

const collections = {
  'n/a-n/a': { label: 'n/a', uri: '' },
  'HouseOfLor-Bangle': { label: 'House Of Lor Bangles', uri: '/collection/house-of-lor/bangles' },
  'HouseOfLor-Bracelet': { label: 'House Of Lor Bracelets', uri: '/collection/house-of-lor/bracelets' },
  'HouseOfLor-Chain': { label: 'House Of Lor Chains', uri: '/collection/house-of-lor/chains' },
  'HouseOfLor-Earrings': { label: 'House Of Lor Earrings', uri: '/collection/house-of-lor/earrings' },
  'HouseOfLor-Pendant': { label: 'House Of Lor Pendants', uri: '/collection/house-of-lor/pendants' },
  'HouseOfLor-Ring': { label: 'House Of Lor Rings', uri: '/collection/house-of-lor/rings' },
  'ModernIrish-Bangle': { label: 'Modern Irish Bangles', uri: '/collection/modern-irish/bangles' },
  'ModernIrish-Bracelet': { label: 'Modern Irish Bracelets', uri: '/collection/modern-irish/bracelets' },
  'ModernIrish-Chain': { label: 'Modern Irish Chains', uri: '/collection/modern-irish/chains' },
  'ModernIrish-Earrings': { label: 'Modern Irish Earrings', uri: '/collection/modern-irish/earrings' },
  'ModernIrish-Pendant': { label: 'Modern Irish Pendants', uri: '/collection/modern-irish/pendants' },
  'ModernIrish-Ring': { label: 'Modern Irish Rings', uri: '/collection/modern-irish/rings' },
  'ModernIrish-Watch': { label: 'Modern Irish Watches', uri: '/collection/modern-irish/watches' },
  'EngagementRings-ClassicSolitaire': { label: 'Classic Solitaire Rings', uri: '/collection/engagement-rings/classic-solitaire-rings' },
  'EngagementRings-CelticEngagementRing': { label: 'Celtic Engagement Rings', uri: '/collection/engagement-rings/celtic-engagement-rings' },
  'Celtic-Bangle': { label: 'Celtic Bangles', uri: '/collection/celtic/bangles' },
  'Celtic-Bracelet': { label: 'Celtic Bracelets', uri: '/collection/celtic/bracelets' },
  'Celtic-Brooch': { label: 'Celtic Brooches', uri: '/collection/celtic/brooches' },
  'Celtic-Cufflinks': { label: 'Celtic Cufflinks', uri: '/collection/celtic/cufflinks' },
  'Celtic-Earrings': { label: 'Celtic Earrings', uri: '/collection/celtic/earrings' },
  'Celtic-Pendant': { label: 'Celtic Pendants', uri: '/collection/celtic/pendants' },
  'Celtic-Ring': { label: 'Celtic Rings', uri: '/collection/celtic/rings' },
  'WeddingBands-CelticWeddingBand': { label: 'Celtic Wedding Rings', uri: '/collection/wedding-bands/celtic-wedding-bands' },
  'WeddingBands-DiamondWeddingBand': { label: 'Diamond Wedding Bands', uri: '/collection/wedding-bands/diamond-wedding-bands' },
};

const ringSizes = {
  ireland: [
    'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
  us: [
    '3', '3.5', '4', '4.5', '4.75', '5.25', '5.75', '6.25', '6.75', '7', '7.5',
    '8', '8.5', '9', '9.75', '10.25', '10.75', '11.25', '11.75', '12', '12.5'
  ],
};

const orderMode = {
  live: 'Live',
  test: 'Test',
};

const orderStatus = {
  processing: 'Processing',
  shipped: 'Shipped',
  refunded: 'Refunded',
  cancelled: 'Cancelled'
};

export {
  collections,
  orderMode,
  orderStatus,
  pages,
  ringSizes
};

export default {
  collections,
  orderMode,
  orderStatus,
  pages,
  ringSizes,
};
