<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">
    <div class="row">
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="filters.filename" (ngModelChange)="onModelChange($event)"
          placeholder="Filter by Image File Name"><br />
      </div>
      <div class="col-9 filters">
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('all')">Show All <i class="far"
            [ngClass]="{'fa-check-square': filters.status === 'all', 'fa-square': filters.status !== 'all'}"></i></button>
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('Used')">Used <i class="far"
            [ngClass]="{'fa-check-square': filters.status === 'Used', 'fa-square': filters.status !== 'Used'}"></i></button>
        <button type="button" class="btn btn-outline-primary" (click)="filterStatus('Not Used')">Not Used <i class="far"
            [ngClass]="{'fa-check-square': filters.status === 'Not Used', 'fa-square': filters.status !== 'Not Used'}"></i></button>
      </div>
    </div>
    <div class="row fade-in" *ngIf="imageListService.imagelist$ | async as imageList">
      <div class="col-6 offset-sm-6">
        <label class="label-right">Total: {{imageList.metadata.totalCount}}</label>
        <nav>
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="imageList.metadata.totalCount"
            [(page)]="filters.page" [pageSize]="imageList.metadata.pageSize" (pageChange)="goToPage($event)"
            [maxSize]="4" [rotate]="true" [boundaryLinks]="true">
          </ngb-pagination>
        </nav>
      </div>
    </div>

    <ng-template #stockItemsLoading>
      <div class="row loading">
        <div style="width: 100%"><i class="fas fa-spinner fa-5x fa-spin"></i></div>
      </div>
    </ng-template>

    <div class="row fade-in" *ngIf="imageListService.imagelist$ | async as imageList">
      <div class="col-12">
        <div class="table-responsive">
          <form #imageListForm="ngForm">
            <div class="row">
              <div class="col-9">
                <h1>Images</h1>
              </div>
            </div>
            <div class="row image-results fade-in" *ngIf="imageListService.images$ | async as images; else imagesLoading">
              <div *ngIf="images.result.length > 0; then imageResults else imagesNotFound"></div>
  
              <ng-template #imageResults>
                <div class="col-md-3" *ngFor="let image of images.result">
                  <span class="stockCode"><b>{{image.fileName}}</b></span><br />
                  <span class="stockImage"><img [src]="image.safeImageUrl"
                      (click)="linkImageToItem(image, stockItem); myDrop.close()" class="img-thumbnail"></span>
                </div>
              </ng-template>
  
              <ng-template #imagesNotFound>
                <div class="row not-found-message">
                  <p>Matching images not found</p>
                </div>
              </ng-template>
  
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row fade-in" *ngIf="imageListService.imagelist$ | async as imageList">
      <div class="col-sm-6 offset-sm-6">
        <label class="label-right">Total: {{imageList.metadata.totalCount}}</label>
        <nav>
          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="imageList.metadata.totalCount"
            [(page)]="filters.page" [pageSize]="imageList.metadata.pageSize" (pageChange)="goToPage($event)"
            [maxSize]="4" [rotate]="true" [boundaryLinks]="true">
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>
</section>