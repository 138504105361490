import { pick as _pick, cloneDeep as _cloneDeep } from 'lodash-es';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { PaymentIntentService } from '../../services/payment-intent.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { TitleService } from '../../services/title.service';
import { NgForm } from '@angular/forms';
import { countries } from '../../utils';


declare let Stripe: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  @ViewChild('checkout') public checkoutForm: NgForm;
  @ViewChild('shipping') public shippingForm: NgForm;
  step = {
    shippingDetails: true,
    billingDetails: false,
    orderSummary: false
  };
  paymentState = {
    submitting: false,
    successful: false
  };
  stripe = Stripe(environment.stripe.key);
  card;
  canMakePaymentRequest = false;
  data = {
    shipping: {
      name: '',
      email: '',
      phone: '',
      address: { line1: '', city: '', state: '', postal_code: '', country: 'US'},
    },
    billing: {
      name: '',
      email: '',
      phone: '',
      address: { line1: '', city: '', state: '', postal_code: '', country: 'US'},
    },
    useShippingAsBilling: true
  };
  errorMessage = '';
  countries = countries;
  token = '';
  cardOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#2f9122',
        color: '#2f9122',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  };


  constructor(
    private paymentIntentService: PaymentIntentService,
    private toasterService: ToastrService,
    public shoppingCartService: ShoppingCartService,
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.titleService.set('Checkout');
    this.breadcrumbService.set('Checkout');

    this.renderCheckout();
  }

  goToStep(step: string): void {
    this.step.shippingDetails = (step === 'shipping-details');
    this.step.billingDetails = (step === 'billing-details');
  }

  validateShipping(): void {
    if(this.data.useShippingAsBilling) {
      this.data.billing = _cloneDeep(this.data.shipping);
    }
    this.goToStep('billing-details');
    this.googleAnalyticsService.emitEvent('checkout_progress', 'ecommerce');
  }

  renderCheckout(): void {
    const elements = this.stripe.elements();
    this.card = elements.create('card', { hidePostalCode: true });
    this.card.mount('#checkout-card-element');
    this.card.on('change', ({error}) => {
      if (error) {
        this.errorMessage = error.message;
        //this.toasterService.error('Something went wrong.', error.message, { closeButton: true });
      } else {
        this.errorMessage = '';
      }
    });
    this.createPaymentRequestElement(elements);
    this.createPaymentIntent();
    //this.goToStep('billing-details');
    //this.paymentState.submitting = true;
  }

  createPaymentIntent(): void {
    this.paymentIntentService.create();
  }

  completedPaymentIntent(): void {
    this.paymentIntentService.completed();
  }

  async submitPayment(): Promise<any> {
    this.paymentState.submitting = true;
    const clientSecret = this.paymentIntentService.paymentIntentClientSecret;
    const saleMetadata = await this.paymentIntentService.buy({
      shipping: this.data.shipping,
      billing: this.data.billing,
    });
    const { paymentIntent, error } = await this.stripe.confirmCardPayment(
      clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: this.data.billing
        },
        shipping: _pick(this.data.shipping, ['address', 'name', 'phone']),
        receipt_email: this.data.billing.email,
        metadata: saleMetadata
      },
    );
    if(error) {
      this.paymentState.submitting = false;
      this.errorMessage = error.message;
      this.toasterService.error('Payment failed.', error.message, { closeButton: true });
    } else {
      if(paymentIntent.status === 'succeeded') {
        this.completedPaymentIntent();
        this.toasterService.success('Payment successful!', paymentIntent.status, { closeButton: true });
      }
      this.paymentState.submitting = false;
      this.paymentState.successful = true;
    }
  }

  createPaymentRequestElement(elements): void {
    /**
     * Payment Request Element
     */
    const paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        amount: 2500,
        label: 'Total'
      },
      requestShipping: true,
      shippingOptions: [
        {
          id: 'free-shipping',
          label: 'Free shipping',
          detail: 'Arrives in 5 to 7 days',
          amount: 0
        }
      ]
    });
    paymentRequest.on('token', function(result) {
      this.toasterService.success('Stripe Token Created.', result.token.id, { closeButton: true });
      this.goToStep('payment-successful');
      result.complete('success');
    });

    const paymentRequestElement = elements.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'light'
        }
      }
    });
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        this.canMakePaymentRequest = true;
        paymentRequestElement.mount('#checkout-payment-request');
      }
    });
  }
}
