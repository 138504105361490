import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MobileMenuService {

  _state: any = this._initializeMenuState();

  public get isOpen(): boolean {
    return this._state.isOpen;
  }

  public get state(): any {
    return this._state;
  }

  openMenu(): void {
    this._state.isOpen = true;
  }

  closeMenu(): void {
    this._state.isOpen = false;
    setTimeout(() => {
      this._state = this._initializeMenuState();
    }, 300);
  }

  _initializeMenuState(): any {
    return {
      isOpen: false,
      shop: {
        isOpen: false,
        engagementRings: {
          isOpen: false,
          menu: {}
        },
        weddingRings: {
          isOpen: false,
          menu: {}
        },
        modernIrish: {
          isOpen: false,
          menu: {}
        },
        celtic: {
          isOpen: false,
          menu: {}
        },
        houseOfLor: {
          isOpen: false,
          menu: {}
        },
      },
      aboutUs: {
        isOpen: false,
        engagementRings: {
          isOpen: false,
        },
        weddingRings: {
          isOpen: false,
          menu: {}
        },
        modernIrish: {
          isOpen: false,
          menu: {}
        },
        celtic: {
          isOpen: false,
          menu: {}
        },
        houseOfLor: {
          isOpen: false,
          menu: {}
        },
      }
    };
  }
}
