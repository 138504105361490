<header style="height: auto;">

  <app-top-bar></app-top-bar>

  <div class="container">
    <div class="row">
      <div class="mobile-menu-button col-3 d-lg-none">
        <span class="fas fa-bars pointer" (click)="mobileMenuService.openMenu()"></span>
      </div>
      <div class="logo col-6 col-lg-3">
        <a class="logo" routerLink="/" aria-label="Home">
          <img src="/assets/images/logos/logo-green-silver.svg" alt="Irish Celtic Jewellery" />
        </a>
      </div>
      <div class="search-button col-1 d-lg-none">
        <span (click)="mobileSearchBoxShown = !mobileSearchBoxShown; focusSearch()" class="pointer">
          <span class="fas fa-search" [hidden]="mobileSearchBoxShown"></span>
          <span class="fas fa-times" [hidden]="!mobileSearchBoxShown"></span>
        </span>
      </div>
      <div class="cart-button col-2 d-lg-none">
        <a routerLink="/shopping-cart">
          <span class="fas fa-shopping-cart"></span>
          <span *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart">
            <span class="badge badge-pill badge-primary" *ngIf="shoppingCart.itemsCount > 0">{{shoppingCart.itemsCount}}</span>
          </span>
        </a>
      </div>
      <div class="header-menu d-none d-lg-inline-block col">
        <ul class="nav justify-content-end">
          <app-shop-menu></app-shop-menu>
          <li class="nav-item dropdown" 
            (mouseenter)="aboutUsMenuShown=true"
            (mouseleave)="aboutUsMenuShown=false">
            <span class="dropdown-toggle nav-link">About Us</span>
            <ul class="dropdown-menu row" [hidden]="showFixedHeader || !aboutUsMenuShown">
              <li><a routerLink="/about-us">About Us</a></li>
              <li><a routerLink="/contact-us">Contact Us</a></li>
              <!-- <li><a routerLink="/delivery-information">Delivery Information</a></li>
              <li><a routerLink="/choosing-a-ring">Choosing a Ring</a></li> -->
              <li><a routerLink="/returns">Returns Policy</a></li>
              <li><a routerLink="/privacy">Privacy</a></li>
              <li><a routerLink="/disclaimer">Disclaimer</a></li>
              <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
              <li><a routerLink="/terms">Terms &amp; Conditions</a></li>
            </ul>
          </li>
          <li *ngIf="!auth.isAdmin" class="nav-item">
            <a href="https://blog.irishcelticjewellery.com/" class="nav-link">Blog</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/favourites">Favourites <i class="fas fa-heart"></i></a>
          </li>
          <li class="nav-item">
            <div ngbDropdown #myDrop1="ngbDropdown" class="dropdown shopping-cart" placement="bottom-right"
              *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart"
              (mouseenter)="$event.stopPropagation(); myDrop1.open();"
              (mouseleave)="$event.stopPropagation(); myDrop1.close();">
              <a ngbDropdownToggle routerLink="/shopping-cart" class="nav-link">Cart <i class="fas fa-shopping-cart"></i></a>
              <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right cart-dropdown" (click)="myDrop1.close()">
                <li class="cart-header">
                  <div class="items-count">{{shoppingCart.items.length}} item{{ shoppingCart.items.length !== 1 ? 's' : ''}} in cart</div>
                  <div class="items-subtotal">Cart Subtotal: <b>{{shoppingCart.formattedTotalAmount}}</b></div>
                  <button type="button" class="btn btn-link btn-block" routerLink="/shopping-cart">View and Edit Cart</button>
                </li>
                <div class="items">
                  <li *ngFor="let item of shoppingCart.items; let i = index">
                    <div class="row item">
                      <div class="col-3">
                        <a [routerLink]="item.product.safeProductUrl">
                          <img [src]="item.product.safeImageUrl" [alt]="item.product.title" [appImgSrcSet]="item.product.originalImageUrl" sizes="60px">
                        </a>
                      </div>
                      <div class="col-9 details">
                        <div class="ellipsis">
                          <a [routerLink]="item.product.safeProductUrl">{{item.product.title}}</a>
                        </div>
                        <div class="price"><span class="label">PRICE:</span> {{item.product.formattedPrice}}</div>
                        <div class="ring-size"><span *ngIf="item.ringSize" >Ring Size: <span class="size">{{item.ringSize}}</span></span></div>
                        <div class="remove"><i class="fas fa-trash pointer" (click)="$event.stopPropagation(); removeFromCart(item)"></i></div>
                      </div>
                    </div>
                  </li>
                </div>
                <li class="actions">
                  <button type="button" class="btn btn-primary btn-block" routerLink="/checkout">Proceed to Check Out</button>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item dropdown" *ngIf="auth.isAuthenticated && auth.isAdmin" 
            (mouseenter)="adminMenuShown=true"
            (mouseleave)="adminMenuShown=false">
            <span class="dropdown-toggle nav-link" [ngClass]="{'selected': currentPage.adminOverview || currentPage.adminManageStock || currentPage.adminManageImages || currentPage.adminOrders}">Admin</span>
            <ul class="dropdown-menu row" [hidden]="showFixedHeader || !adminMenuShown">
              <li><a routerLink="/admin/overview" [ngClass]="{'selected': currentPage.adminOverview}">Overview</a></li>
              <li><a routerLink="/admin/stock-list" [ngClass]="{'selected': currentPage.adminManageStock}">Manage Stock</a></li>
              <li><a routerLink="/admin/image-list" [ngClass]="{'selected': currentPage.adminManageImages}">Manage Images</a></li>
              <li><a routerLink="/admin/orders" [ngClass]="{'selected': currentPage.adminOrders}">Orders</a></li>
            </ul>
          </li>
          <li *ngIf="!auth.isAuthenticated">
            <button class="btn btn-outline-primary login-signup" (click)="auth.login()">Login / Sign Up</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container" [hidden]="!mobileSearchBoxShown">
    <div class="row">
      <div class="col-12 offset-md-6 col-md-6">
        <div class="input-group search-box">
          <input type="text" #searchInput class="form-control" [(ngModel)]="term" (keyup)="handleKeyUp($event)" placeholder="Search for something...">
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="search()">&nbsp;Search <span class="fas fa-search"></span>&nbsp;</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed" [class.fixed-show]="showFixedHeader">
    <div class="container">
      <div class="row">
        <div class="mobile-menu-button col-3 d-lg-none">
          <span class="fas fa-bars pointer" (click)="mobileMenuService.openMenu()"></span>
        </div>
        <div class="logo col-6 col-lg-3">
          <a class="logo" routerLink="/" aria-label="Home">
            <img src="/assets/images/logos/logo-green-silver.svg" alt="Irish Celtic Jewellery" />
          </a>
        </div>
        <div class="search-button col-1 d-lg-none">
          <span (click)="mobileSearchBoxShown = !mobileSearchBoxShown; focusSearch()" class="pointer">
            <span class="fas fa-search" [hidden]="mobileSearchBoxShown"></span>
            <span class="fas fa-times" [hidden]="!mobileSearchBoxShown"></span>
          </span>
        </div>
        <div class="cart-button col-2 d-lg-none">
          <a routerLink="/shopping-cart">
            <span class="fas fa-shopping-cart"></span>
            <span *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart">
              <span class="badge badge-pill badge-primary" *ngIf="shoppingCart.itemsCount > 0">{{shoppingCart.itemsCount}}</span>
            </span>
          </a>
        </div>
        <div class="header-menu d-none d-lg-inline-block col">
          <ul class="nav justify-content-end">
            <app-shop-menu></app-shop-menu>
            <li class="nav-item dropdown" 
              (mouseenter)="aboutUsMenuShown=true"
              (mouseleave)="aboutUsMenuShown=false">
              <span class="dropdown-toggle nav-link">About Us</span>
              <ul class="dropdown-menu row" [hidden]="!showFixedHeader || !aboutUsMenuShown">
                <li><a routerLink="/about-us">About Us</a></li>
                <li><a routerLink="/contact-us">Contact Us</a></li>
                <!-- <li><a routerLink="/delivery-information">Delivery Information</a></li>
                <li><a routerLink="/choosing-a-ring">Choosing a Ring</a></li> -->
                <li><a routerLink="/returns">Returns Policy</a></li>
                <li><a routerLink="/privacy">Privacy</a></li>
                <li><a routerLink="/disclaimer">Disclaimer</a></li>
                <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                <li><a routerLink="/terms">Terms &amp; Conditions</a></li>
              </ul>
            </li>
            <li *ngIf="!auth.isAdmin" class="nav-item">
              <a href="https://blog.irishcelticjewellery.com/" class="nav-link">Blog</a>
            </li>
            <li class="nav-item">
              <a routerLink="/favourites" class="nav-link">Favourites <i class="fas fa-heart"></i></a>
            </li>
            <li class="nav-item">
              <div ngbDropdown #myDrop2="ngbDropdown" class="dropdown shopping-cart" placement="bottom-right"
                *ngIf="shoppingCartService.shoppingCart$ | async as shoppingCart"
                (mouseenter)="$event.stopPropagation(); myDrop2.open();"
                (mouseleave)="$event.stopPropagation(); myDrop2.close();">
                <a ngbDropdownToggle routerLink="/shopping-cart" class="nav-link">Cart <i
                    class="fas fa-shopping-cart"></i></a>
                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right cart-dropdown" (click)="myDrop2.close()">
                  <li class="cart-header">
                    <div class="items-count">{{shoppingCart.items.length}} item{{ shoppingCart.items.length !== 1 ? 's' : ''}} in cart</div>
                    <div class="items-subtotal">Cart Subtotal: <b>{{shoppingCart.formattedTotalAmount}}</b></div>
                    <button type="button" class="btn btn-link btn-block" routerLink="/shopping-cart">View and Edit Cart</button>
                  </li>
                  <div class="items">
                    <li *ngFor="let item of shoppingCart.items; let i = index">
                      <div class="row item">
                        <div class="col-3">
                          <a [routerLink]="item.product.safeProductUrl">
                            <img [src]="item.product.safeImageUrl" [alt]="item.product.title" [appImgSrcSet]="item.product.originalImageUrl" sizes="60px">
                          </a>
                        </div>
                        <div class="col-9 details">
                          <div class="ellipsis">
                            <a [routerLink]="item.product.safeProductUrl">{{item.product.title}}</a>
                          </div>
                          <div class="price"><span class="label">PRICE:</span> {{item.product.formattedPrice}}</div>
                          <div class="ring-size"><span *ngIf="item.ringSize" >Ring Size: <span class="size">{{item.ringSize}}</span></span></div>
                          <div class="remove"><i class="fas fa-trash pointer" (click)="$event.stopPropagation(); removeFromCart(item)"></i></div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <li class="actions">
                    <button type="button" class="btn btn-primary btn-block" routerLink="/checkout">Proceed to Check Out</button>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item dropdown" *ngIf="auth.isAuthenticated && auth.isAdmin" 
              (mouseenter)="adminMenuShown=true"
              (mouseleave)="adminMenuShown=false">
              <span class="dropdown-toggle nav-link" [ngClass]="{'selected': currentPage.adminOverview || currentPage.adminManageStock || currentPage.adminManageImages || currentPage.adminOrders}">Admin</span>
              <ul class="dropdown-menu row" [hidden]="!showFixedHeader || !adminMenuShown">
                <li><a routerLink="/admin/overview" [ngClass]="{'selected': currentPage.adminOverview}">Overview</a></li>
                <li><a routerLink="/admin/stock-list" [ngClass]="{'selected': currentPage.adminManageStock}">Manage Stock</a></li>
                <li><a routerLink="/admin/image-list" [ngClass]="{'selected': currentPage.adminManageImages}">Manage Images</a></li>
                <li><a routerLink="/admin/orders" [ngClass]="{'selected': currentPage.adminOrders}">Orders</a></li>
              </ul>
            </li>
            <li *ngIf="!auth.isAuthenticated">
              <button class="btn btn-outline-primary login-signup" (click)="auth.login()">Login / Sign Up</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="container" [hidden]="!mobileSearchBoxShown">
        <div class="row">
          <div class="col-12 offset-md-6 col-md-6">
            <div class="input-group search-box">
              <input type="text" #searchInputFixed class="form-control" [(ngModel)]="term" (keyup)="handleKeyUp($event)" placeholder="Search for something...">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="search()">&nbsp;Search <span class="fas fa-search"></span>&nbsp;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>