import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../model/product';
import { Image } from '../model/image';
import { environment } from '../../environments/environment';
import { convertToQueryString, applyImageFormatting } from '../utils';

@Injectable({ providedIn: 'root' })
export class StockListService {

  fetchParams: Record<string, string | number | boolean>;
  stocklist$: Observable<Product[]>;
  images$: Observable<Image[]>;

  constructor(private http: HttpClient, private toasterService: ToastrService) { }

  url = (params: Record<string, string | number | boolean>): string => `${environment.api}/v1/stockitems?${convertToQueryString(params)}`;
  imageUrl = (params: Record<string, string | number | boolean>): string => `${environment.api}/v1/images?${convertToQueryString(params)}`;
  stockItemsUrl = (): string => `${environment.api}/v1/stockitems`;
  stockItemUrl = (stockItemId: string): string => `${environment.api}/v1/stockitems/${stockItemId}`;
  stockItemByCodeUrl = (stockItemCode: string): string => `${environment.api}/v1/stockitems/${stockItemCode}`;

  fetch(params: Record<string, string | number | boolean>): Observable<Product[]> {
    this.fetchParams = params;
    console.log(`stock-list.service.fetch: ${JSON.stringify(params)}`);
    this.stocklist$ = this.http.get<Product[]>(this.url(params)).pipe(
      share(),
      tap((data: any) => {
        data.result.forEach(product => {
          Product.enrich(product);
        });
        data.metadata.pages = Array(Math.ceil(data.metadata.totalCount / data.metadata.pageSize));
      })
    );
    return this.stocklist$;
  }

  refetch(): void {
    console.log(`stock-list.service.refetch: ${JSON.stringify(this.fetchParams)}`);
    this.fetch(this.fetchParams);
  }

  fetchStockItemByCode(code): Observable<Product> {
    const url = this.stockItemByCodeUrl(code);
    const result = this.http.get<Product>(url).pipe(
      tap((product: Product) => {
        Product.enrich(product);
      })
    );
    return result;
  }

  fetchImages(term: string): Observable<Image[]> {
    console.log(`stock-list.service.fetchImages: ${term}`);
    this.images$ = this.http.get<Image[]>(this.imageUrl({ term })).pipe(
      share(),
      tap((data: any) => {
        data.result.forEach(image => {
          image.safeImageUrl = `${environment.image.root}${image.fileName}${environment.image.thumbnail}`;
          image.code = image.fileName;
        });
        data.metadata.pages = Array(Math.ceil(data.metadata.totalCount / data.metadata.pageSize));
      })
    );
    return this.images$;
  }

  deleteStockItem(product: any): Subscription {
    console.log(`stock-list.service.deleteStockItem: ${JSON.stringify(product)}`);
    return this.http.delete(this.stockItemUrl(product.id))
      .subscribe(data => {
        this.toasterService.success('Stock item deleted.', 'Successful!');
        product.changed = false;
      }, () => {
        product.changed = true;
      });
  }

  createStockItem(product: any): Subscription {
    console.log(`stock-list.service.cloneStockItem: ${JSON.stringify(product)}`);
    return this.http.post(this.stockItemsUrl(), product)
      .subscribe(data => {
        this.toasterService.success('Stock item created.', 'Successful!');
        product.changed = false;
      }, () => {
        product.changed = true;
      });
  }

  saveStockItem(product: any, callback = () => {}): Subscription {
    console.log(`stock-list.service.saveStockItem: ${JSON.stringify(product)}`);
    product.price = Math.round(product.priceInEuros * 100);
    product.wholeSalePrice = Math.round(product.wholeSalePriceInEuros * 100);

    return this.http.put<Product>(this.stockItemUrl(product.id), product)
      .subscribe(data => {
        this.toasterService.success('Stock item saved.', 'Successful!');
        product.galleryItemId = data.galleryItemId; // This field may have just been set
        product.slug = data.slug; // This field may have just been set
        applyImageFormatting(product);
        product.changed = false;
        Product.enrich(product);
        callback();
      }, () => {
        product.changed = true;
      });
  }
}
