import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, takeLast, tap } from 'rxjs/operators';
import { GalleryThumbnail } from '../model/galleryThumbnail';
import { environment } from './../../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({ providedIn: 'root' })
export class GalleryService {

  gallery$: Observable<any>;

  constructor(
    private http: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  url = (gallery: string, page: number): string => `${environment.api}/v1/gallery/${gallery}?page=${page}`;

  fetch(galleryCode: string, page = 1): Observable<any> {
    this.gallery$ = this.http.get<GalleryThumbnail[]>(this.url(galleryCode, page)).pipe(
      shareReplay(1),
      tap((data: any) => {
        data.result.forEach(galleryItem => {
          GalleryThumbnail.enrichGalleryItem(galleryItem);
        });
        data.metadata.pages = Array(Math.ceil(data.metadata.totalCount / data.metadata.pageSize));
      })
    );
    this.googleAnalyticsService.emitEvent('view_item_list', 'engagement');
    return this.gallery$;
  }
}
