import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sortBy as _sortBy, split as _split } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TitleService } from '../../services/title.service';
import { StockListService } from '../..//services/stock-list.service';
import { collections } from '../../app.config';
import { encodeSex } from '../../utils';
import { Product } from '../../model/product';
import { Image } from '../../model/image';


@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss']
})
export class StockListComponent implements OnInit, OnDestroy {

  collections = {};
  filters = { status: undefined, page: 1, code: '', gallery: 'all-all', collection: 'all', type: 'all', supplier: 'all' };
  imageSearch = '';
  statuses = ['Active', 'Discontinued', 'Needs Work', 'New', 'Pending Review'];
  galleryList = _sortBy(Object.keys(collections));
  suppliers = ['Blackman', 'Ansley', 'TJH', 'n/a'];

  search$ = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public stockListService: StockListService,
  ) {
    this.collections = collections;
    this.route.queryParams.subscribe(({ status = 'all', page = 1, code = '', collection = 'all', type = 'all', supplier = 'all' }) => {
      this.filters.status = status;
      this.filters.page = page;
      this.filters.code = code;
      this.filters.collection = collection;
      this.filters.type = type;
      this.filters.supplier = supplier;
    });

    this.search$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        console.log(`this.filters.code = ${this.filters.code}, searchText = ${searchText}`);
        this.filters.code = searchText;
        return this.fetch();
      })
    ).subscribe(response => {
      console.log('in search$ subscribe', response);
    });
  }

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    this.titleService.set('Manage Stock');
    this.breadcrumbService.set('Manage Stock');
    this.fetch();
  }

  onModelChange(value: string): void {
    this.search$.next(value);
  }

  fetch(): Observable<Product[]> {
    const { status, collection, type, supplier, page, code } = this.filters;
    const filter = code !== '' ? `code~'${code}'` : '';
    console.log(`stock-list fetch filter: ${JSON.stringify({ status, collection, type, supplier, page, filter })}`);
    return this.stockListService.fetch({ status, collection, type, supplier, page, filter });
  }

  filterGallery(): void {
    const [collection, type] = _split(this.filters.gallery, '-');
    this.filters.type = type;
    this.filters.collection = collection;
    this.fetch();
  }

  filterStatus(status): void {
    this.filters.status = status;
    this.fetch();
  }

  goToPage(page): void {
    this.filters.page = page;
    this.fetch();
  }

  ngOnDestroy(): void {
    if (this.search$) {
      this.search$.unsubscribe();
    }
  }
}
