import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit, AfterViewInit {

  @ViewChild('my-youtube-player') videoContainer: ElementRef;

  @Input() videoId = '';

  parentElement: Element;
  width = 0;
  height = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private eventManager: EventManager,
    private utilService: UtilService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.parentElement = this.elementRef.nativeElement.parentElement;
      //this.eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
      this.onResize();
      if (!this.utilService.isYoutubeAPILoaded()) {
        // This code loads the IFrame Player API code asynchronously, according to the instructions at
        // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.onload = () => {
          this.utilService.setYoutubeAPILoaded(true);
        };
        document.body.appendChild(tag);
      }
    }
  }

  ngAfterViewInit() {
  }

  onResize(event?) {
    this.width = this.elementRef.nativeElement.parentElement.offsetWidth;
    this.height = this.width * 9/16;
    console.log(`Resize event: ${this.width} x ${this.height}`);
  }
}
