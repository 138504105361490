<div class="top-bar">
  <div class="container">
    <div class="row">
      <div class="col-md-5 d-none d-md-block">
        <ul class="list-inline">
          <li><a href="https://twitter.com/celticjewelryHQ" aria-label="Twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
          <li><a href="https://www.facebook.com/jewelryHQ" aria-label="Facebook" target="_blank"><i class="fab fa-facebook"></i></a></li>
          <li><a href="https://www.instagram.com/irishcelticjewellery/" aria-label="Instagram" target="_blank"><i
            class="fab fa-instagram"></i></a></li>
          <li>
            <span class="call-us"><a href="callto:+353858666215">Call us <i class="fas fa-phone"></i>
                +353 85 866 6215</a></span>
          </li>
        </ul>
      </div>
      <div class="col-md-7 col-12">
        <ul class="list-inline float-right top-right">
          <li class="search-box d-none d-lg-block">
            <div class="input-group">
              <input type="text" class="form-control search" [(ngModel)]="term" (keyup)="handleKeyUp($event)" placeholder="Search for something...">
              <div class="input-group-append">
                <span class="input-group-text search-button" (click)="searchService.navigate(term)"><i class="fas fa-search"></i></span>
              </div>
            </div>
          </li>
          <!-- <li ngbDropdown #myDrop="ngbDropdown" class="currency dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
              &euro; Euro
            </a>
            <ul class="dropdown-menu">
              <li><a href="#" data-currency="eur">&euro; Euro</a></li>
              <li><a href="#" data-currency="usd">$ US Dollar</a></li>
              <li><a href="#" data-currency="gbp">&pound; Sterling</a></li>
            </ul>
          </li> -->
          <li class="username" *ngIf="auth.isAuthenticated"><span class="ellipsis">{{auth.name}}</span></li>
          <li class="account-logout" *ngIf="auth.isAuthenticated"><span (click)="auth.logout()">Log Out <i class="fas fa-sign-out-alt"></i></span></li>
        </ul>
      </div>
    </div>
  </div>
</div>