import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { remove as _remove } from 'lodash-es';
import { environment } from '../../environments/environment';
import { GalleryThumbnail } from '../model/galleryThumbnail';

@Injectable({ providedIn: 'root' })
export class FavouritesService {

  favourites$: Observable<GalleryThumbnail[]>;
  favourites: Array<string>;
  _favouritesMap: any = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private http: HttpClient,
  ) { }

  url = (): string => `${environment.api}/v1/favourites`;

  load(): Array<string> {
    if (isPlatformBrowser(this.platformId)) {
      this.favourites = JSON.parse(localStorage.getItem('favourites')) || [];
      this._syncFavouritesMap(this.favourites);
      return this.favourites;
    }
    return [];
  }

  _store(favourites: Array<string>): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('favourites', JSON.stringify(favourites));
      this._syncFavouritesMap(favourites);
    }
  }

  _syncFavouritesMap(favourites: Array<string>): void {
    this.favourites = favourites;
    this._favouritesMap = {};
    favourites.forEach(x => this._favouritesMap[x] = true);
  }

  public add(galleryItemCode: string): void {
    const favourites = this.load();
    favourites.push(galleryItemCode);
    this._store(favourites);
  }

  public remove(galleryItemCode: string): void {
    const favourites = this.load();
    _remove(favourites, (favourite) => favourite === galleryItemCode);
    this._store(favourites);
  }

  get favouritesMap(): any {
    return this._favouritesMap;
  }

  fetch(): Observable<GalleryThumbnail[]> {
    if(isPlatformBrowser(this.platformId)) {
      const favourites = this.load();
      if(favourites.length > 0) {
        this.favourites$ = this.http.post<GalleryThumbnail[]>(this.url(), favourites).pipe(
          share(),
          tap((data: GalleryThumbnail[]) => {
            data.forEach(galleryItem => {
              GalleryThumbnail.enrichGalleryItem(galleryItem);
            });
          })
        );
      } else {
        this.favourites$ = of([]);
      }
    }
    return this.favourites$;
  }
}
