<app-breadcrumb></app-breadcrumb>

<section class="mainContent">
  <div class="container">

    <ng-template #searchLoading>
      <div class="row loading-message">
        <div style="width: 100%"><i class="fas fa-spinner fa-5x fa-spin"></i></div>
      </div>
    </ng-template>

    <div class="row" *ngIf="favouritesService.favourites$ | async as favourites; else searchLoading">
      <div class="col-12">

        <div *ngIf="favourites.length > 0; then showFavourites else noFavourites"></div>

        <ng-template #noFavourites>
          <div class="col-12 not-found-message">
            <h2>No favourites added yet <i class="far fa-frown"></i></h2>
            <p>When you add some favourites, they will appear here!</p>
          </div>
        </ng-template>
  
        <ng-template #showFavourites>
          <div class="col-lg-3 col-md-4" *ngFor="let thumbnail of favourites; let i = index">
            <app-thumbnail [thumbnail]="thumbnail" [index]="i"></app-thumbnail>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</section>