<app-breadcrumb></app-breadcrumb>

<div class="container page">
  <h1>Contact Us</h1>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="section">
        <div class="section-title">Address</div>
        <div class="section-body">
          <p>Irish Celtic Jewellery,<br />
            Swords Road,<br/>
            Malahide,<br />
            Co Dublin,<br />
            K67 E2A0,<br/>
            Ireland
          </p>
        </div>
      </div>
      <div class="section">
        <div class="section-title">Phone</div>
        <div class="section-body phone">
          Rory: <a href="tel:+353858666215"><i class="fas fa-phone"></i> +353 85 866 6215</a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="section">
        <div class="section-title">Email</div>
        <div class="section-body email">
          <p><span class="title">Info:</span>
            <a href="mailto:info@irishcelticjewellery.com"><i class="fas fa-envelope"></i>
              info@irishcelticjewellery.com</a></p>
          <p><span class="title">Sales:</span>
            <a href="mailto:sales@irishcelticjewellery.com"><i class="fas fa-envelope"></i>
              sales@irishcelticjewellery.com</a></p>
          <p><span class="title">Administrator:</span>
            <a href="mailto:admin@irishcelticjewellery.com"><i class="fas fa-envelope"></i>
              admin@irishcelticjewellery.com</a></p>
          <p><span class="title">Privacy:</span>
            <a href="mailto:privacy@irishcelticjewellery.com"><i class="fas fa-envelope"></i>
              privacy@irishcelticjewellery.com</a></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="section">
        <div class="section-title">Where are we?</div>
        <div class="section-body">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20073506.031243134!2d-24.00370372516716!3d52.10922976395629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1fdc406cba7ac4eb!2sIrish+Celtic+Jewellery!5e0!3m2!1sen!2sus!4v1494179360047"
            width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="darkSection">
  <div class="container">
    <h2>Where You Can Find Us</h2>
    <div class="row">
      <div class="col-md-4">
        <div class="thumbnail">
          <div class="caption">
            <h5>Facebook</h5>
            <i class="fab fa-facebook-square"></i><br />
            <p><a href="https://www.facebook.com/jewelryHQ/" target="_blank">@jewelryHQ</a></p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="thumbnail">
          <div class="caption">
            <h5>Twitter</h5>
            <i class="fab fa-twitter-square"></i><br />
            <p><a href="https://twitter.com/celticJewelryHQ" target="_blank">@celticJewelryHQ</a></p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="thumbnail">
          <div class="caption">
            <h5>Instagram</h5>
            <i class="fab fa-instagram-square"></i><br />
            <p><a href="https://instagram.com/irishcelticjewellery" target="_blank">@irishcelticjewellery</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>