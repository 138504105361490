import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sortBy as _sortBy, split as _split } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { TitleService } from '../../services/title.service';
import { ImageListService } from '../../services/image-list.service';
import { Image } from '../../model/image';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit, OnDestroy {

  filters = { status: undefined, page: 1, filename: '' };
  statuses = ['Used', 'Not Used'];

  search$ = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private breadcrumbService: BreadcrumbService,
    public imageListService: ImageListService,
  ) {
    this.route.queryParams.subscribe(({ status = 'all', page = 1, filename = '' }) => {
      this.filters.status = status;
      this.filters.page = page;
      this.filters.filename = filename;
    });

    this.search$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        console.log(`this.filters.code = ${this.filters.filename}, searchText = ${searchText}`);
        this.filters.filename = searchText;
        return this.fetch();
      })
    ).subscribe(response => {
      console.log('in search$ subscribe', response);
    });
  }

  ngOnInit(): void {
    this.render();
  }

  render(): void {
    this.titleService.set('Manage Images');
    this.breadcrumbService.set('Manage Images');
    this.fetch();
  }

  onModelChange(value: string): void {
    this.search$.next(value);
  }

  fetch(): Observable<Image[]> {
    const { status, page, filename } = this.filters;
    const filter = filename !== '' ? `filename~'${filename}'` : '';
    console.log(`image-list fetch filter: ${JSON.stringify({ status, page, filter })}`);
    return this.imageListService.fetch({ status, page, filter });
  }

  filterStatus(status): void {
    this.filters.status = status;
    this.fetch();
  }

  goToPage(page): void {
    this.filters.page = page;
    this.fetch();
  }

  ngOnDestroy(): void {
    if (this.search$) {
      this.search$.unsubscribe();
    }
  }
}
