import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { SearchService } from '../../services/search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  term: string;
  termSubscription: Subscription;

  constructor(
    public auth: AuthService,
    public shoppingCartService: ShoppingCartService,
    public searchService: SearchService
    ) {}

  ngOnInit(): void {
    this.termSubscription = this.searchService.term$.subscribe(term => {
      this.term = term;
    });
  }

  ngOnDestroy(): void {
    if(this.termSubscription) {
      this.termSubscription.unsubscribe();
    }
  }

  handleKeyUp($event): void {
    if($event.keyCode === 13){
      this.searchService.navigate(this.term);
    }
  }
}
