<nav class="d-flex justify-content-end">
  <ul class="pagination">
    <li class="page-item" [ngClass]="{ 'disabled' : pagination.page === 1 }">
      <a *ngIf="pagination.previousPage === 1" class="page-link" [routerLink]="pagination.collectionUrl">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
      <a *ngIf="pagination.previousPage !== 1" class="page-link" [routerLink]="pagination.collectionUrl" [queryParams]="{ page: pagination.previousPage }">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" *ngFor="let page of pagination.pages; let i = index" 
      [class.active]="page === pagination.page"
      [class.disabled]="isEllipsis(page)">
      <span *ngIf="isEllipsis(page)" class="page-link">...</span>
      <a *ngIf="!isEllipsis(page) && pagination.page !== page && page === 1" class="page-link" [routerLink]="page.collectionUrl">{{page}}</a>
      <a *ngIf="!isEllipsis(page) && pagination.page !== page && page !== 1" class="page-link" [routerLink]="page.collectionUrl" [queryParams]="{ page: page }">{{page}}</a>
      <span *ngIf="pagination.page === page" class="page-link">{{page}}</span>
    </li>
    <li class="page-item" [ngClass]="{ 'disabled' : pagination.page === pagination.numberOfPages }">
      <a class="page-link" [routerLink]="pagination.collectionUrl" [queryParams]="{ page: pagination.nextPage }">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
