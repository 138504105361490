<div class="home-banner">
  <img src="https://ik.imagekit.io/irishcelticjewellery/assets/images/home-banner-hol-model.png" 
  [appImgSrcSet]="'https://ik.imagekit.io/irishcelticjewellery/assets/images/home-banner-hol-model.png'"
  sizes="100vw"
  alt="Irish Celtic Jewellery" />
</div>

<section class="mainContent">
  <div class="container">
    <div class="row">
      <div class="col-md-12 title">
        <h1>Looking for Irish Celtic Jewellery?</h1>
        <p>You've come to the right place.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="video-container">
          <app-youtube-player videoId="brS3akXmpS0"></app-youtube-player>
        </div>
      </div>
    </div>
  </div>
</section>